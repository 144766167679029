import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b48f7fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "impressum view-root" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-circle mr-2"
}
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.utils.getTitleClass())
    }, [
      (_ctx.showDot)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : _createCommentVNode("", true),
      _createTextVNode(" Impressum ")
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", { innerHTML: _ctx.impressumDetails }, null, 8, _hoisted_4)
    ])
  ]))
}