import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "zuruck",
  class: "mb-5"
}
const _hoisted_2 = { class: "title mb-3" }
const _hoisted_3 = { id: "tnc-text" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("small", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, "< " + _toDisplayString(_ctx.$t('miscellaneous.back')), 1)
    ]),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('miscellaneous.terms_and_conditions')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", { innerHTML: _ctx.termsAndConditionsContent }, null, 8, _hoisted_4),
      _createElementVNode("p", null, [
        (_ctx.documentURL)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.documentURL
            }, _toDisplayString(_ctx.$t('miscellaneous.download_tnc')), 9, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}