<template>
    <div class="privacy view-root">
        <div id="termstext">
            <p v-html="text"></p>
            <p>
                <a v-if="documentURL" :href="documentURL"
                    >Online-Datenschutzinformation herunterladen</a
                >
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'
import router from '@/router'
import { isMobile } from 'mobile-device-detect'
import commonUtils from '@/shared/mixins/utils'

export default defineComponent({
    setup() {
        const documentURL = store.state.config.pages.privacydetails.file
        // text is base64 encoded, first decode it
        const text = commonUtils.b64DecodeUnicode(store.state.config.pages.privacydetails.text)
        return {
            showDot: store.state.config.display_title_dot,
            router,
            isMobile,
            documentURL,
            text,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';
.privacy {
    background-color: $white;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    min-height: calc(100vh - 170px);
    max-height: calc(100vh - 110px);
    overflow: auto;
}

.title-text {
    display: block !important;
}

.p-component {
    font-family: var(--tqs-font-family);
}
</style>
