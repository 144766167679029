<template>
    <div class="login view-root">
        <div>
            <div :class="utils.getTitleClass()">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                Bestätigen Sie Ihre E-Mail-Adresse
            </div>
            <!-- login form section -->
            <form @submit.prevent="submit">
                <p class="sub-text">
                    Über welche E-Mail-Adresse können wir Kontakt mit Ihnen aufnehmen?<br />
                </p>
                <div class="p-fluid">
                    <div class="field">
                        <span :class="['p-float-label', errors.EMAIL.missing ? 'error' : '']">
                            <InputText
                                id="email"
                                type="text"
                                v-model="LOGIN_FORM.EMAIL"
                                :class="[errors.EMAIL.missing ? 'p-invalid' : '']"
                            />
                            <label for="email">E-Mail-Adresse *</label>
                        </span>
                        <small class="p-error" v-if="errors.EMAIL.missing"
                            >Eingabe erforderlich.</small
                        >
                    </div>
                    <small id="username1-help" v-if="isOTPFlow">
                        An diese Adresse senden wir Ihnen im nächsten Schritt einen sechsstellige
                        Code.
                    </small>
                    <small id="username1-help" v-else>
                        An diese Adresse senden wir Ihnen im nächsten Schritt einen
                        Bestätigungslink.
                    </small>
                </div>
                <div class="mt-3">
                    <small class="p-error" v-if="error">{{ errorMessage }}</small>
                </div>
                <div class="action-wrapper mt-3">
                    <ButtonPV
                        label="Login und weiter"
                        class="primary w-full"
                        id="login-email"
                        type="submit"
                    />
                </div>
            </form>
        </div>
        <!-- loading spinner -->
        <VueLoading
            :opacity="0.8"
            :active="loading"
            :can-cancel="true"
            :is-full-page="true"
            color="#778da0"
        />
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, ref } from 'vue'
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'
import store from '@/store'
import variables from '@/client-app/shared/variables'
import APIService from '@/client-app/shared/APIService'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'

export default defineComponent({
    setup() {
        const conf = store.state.config.pages.login
        const error = ref(false)
        const errorMessage = ref(' ')
        // keep all field model in one object
        const email = router.currentRoute.value.query.email || ''
        const LOGIN_FORM = ref({
            EMAIL: `${email}`,
        })
        let campaign = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.CAMPAIGN)
        if (!campaign && router.currentRoute.value.query.campaign) {
            // When the campaign url variable is set, wrap it in quotes, like localstorage would do.
            // This allows JSON.parse to parse it.
            campaign = `"${router.currentRoute.value.query.campaign}"`
        }
        const freeTextChecked = localStorage.getItem(
            variables.LOCAL_STORAGE_ITEMS.FREE_TEXT_CHECKED
        )
        const loading = ref(false)
        const errors: Ref<ErrorObjectDTO> = ref({
            EMAIL: {
                missing: false,
                invalidForm: false,
            },
        })
        const validateForm = () => {
            // missing checks
            errors.value.EMAIL.missing = utils.validateMandatoryFields(
                LOGIN_FORM.value.EMAIL,
                'string'
            )
            // Basic check if this is a valid email address
            errors.value.EMAIL.invalidForm = !utils.validateEmail(LOGIN_FORM.value.EMAIL.trim())
        }

        const isOTPFlow = store.state.config.authentication_flow === 'otp'

        const getRequest = () => {
            let url_prefix = document.location.origin
            // add campaign query param to the url if campaign value is exist
            const campaignQueryParam = campaign ? 'campaign=' + JSON.parse(campaign) + '&' : ''
            //  add free_text query param to the url
            const freeTextQueryParam =
                freeTextChecked && freeTextChecked !== 'undefined'
                    ? 'freeText=' + JSON.parse(freeTextChecked) + '&'
                    : ''

            let data: { email: string; partner?: string; verify_email_url?: string }

            //  No resubmission year changes for b2b flow
            if (router.currentRoute.value.fullPath.includes('/b2b')) {
                url_prefix += '/b2b'
                // get cpartner
                data = {
                    email: LOGIN_FORM.value.EMAIL,
                    partner: store.state.partner,
                    /* ammending query params to /verify-email/ in order to read when user is
                accessing email-verification url(to be able to continue from different browsers)*/
                    verify_email_url:
                        url_prefix + '/verify-email?' + campaignQueryParam + freeTextQueryParam,
                }
            } else {
                // append selected choices to email verification url(to use in upload)
                const currentYearQueryParam =
                    'current_year=' + JSON.stringify(store.state.tcAndPp?.currentYear) + '&'
                const nextYearQueryParam =
                    'next_year=' + JSON.stringify(store.state.tcAndPp?.nextYear) + '&'
                const emailPromotionQueryParam =
                    'email_promotion=' + JSON.stringify(store.state.tcAndPp?.emailPromotion)

                // get cpartner
                data = {
                    email: LOGIN_FORM.value.EMAIL,
                    partner: store.state.partner,
                    /* ammending query params to /verify-email/ in order to read when user is
                accessing email-verification url(to be able to continue from different browsers)*/
                    verify_email_url:
                        url_prefix +
                        '/verify-email?' +
                        campaignQueryParam +
                        freeTextQueryParam +
                        currentYearQueryParam +
                        nextYearQueryParam +
                        emailPromotionQueryParam,
                }
            }

            return data
        }

        const submit = () => {
            // validate form
            validateForm()
            // check if the form has errors
            if (utils.hasErrors(errors.value) || utils.hasEmojis(LOGIN_FORM.value.EMAIL)) {
                return
            }
            loading.value = true
            const data = getRequest()

            if (isOTPFlow) {
                APIService.loginOTP(data)
                    .then(() => {
                        // Store email to use in the codevalidation step.
                        store.commit('setEmail', data.email)
                        loading.value = false
                        const b2b_suffix: string = router.currentRoute.value.fullPath.includes(
                            'b2b'
                        )
                            ? 'B2B'
                            : ''
                        router.push({
                            name: 'CodeValidation' + b2b_suffix,
                            query: router.currentRoute.value?.query,
                        })
                    })
                    .catch(() => {
                        loading.value = false
                        error.value = true
                        errorMessage.value = conf?.invalid_login
                    })
            } else {
                APIService.loginEmail(data)
                    .then(() => {
                        loading.value = false
                        const b2b_suffix: string = router.currentRoute.value.fullPath.includes(
                            'b2b'
                        )
                            ? 'B2B'
                            : ''
                        router.push({
                            name: 'Confirm' + b2b_suffix,
                            query: {
                                ...router.currentRoute.value?.query,
                                email: data.email,
                            },
                        })
                    })
                    .catch(() => {
                        loading.value = false
                        error.value = true
                        errorMessage.value = conf?.invalid_login
                    })
            }
        }

        return {
            utils,
            conf,
            showDot: store.state.config.display_title_dot,
            LOGIN_FORM,
            submit,
            errors,
            loading,
            error,
            errorMessage,
            isOTPFlow,
        }
    },
})
</script>

<style scoped lang="scss">
* {
    font-family: var(--tqs-font-family);
}
.action-wrapper {
    text-align: center;
}
.p-component {
    font-family: var(--tqs-font-family);
}
</style>
