<template>
    <div class="impressum" v-if="isImpressumEnabled">
        <div class="action-wrapper" v-if="!isInfoVisible" @click="showImpressumInfo">
            <div class="impressume-button-link">Impressum</div>
        </div>

        <div v-if="isInfoVisible && impressumDetails" class="info-wrapper" ref="impressumInfoEl">
            <h4>Impressum</h4>
            <div class="info">
                <span v-html="impressumDetails"> </span>
            </div>

            <div class="move-up">
                <i class="fas fa-times" @click="hideIpressumInfo()"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import store from '@/store'

export default defineComponent({
    setup() {
        const isImpressumEnabled = ref(false)
        const isInfoVisible = ref(false)
        const impressumInfoEl = ref()
        const impressumDetails = ref('')

        const showImpressumInfo = () => {
            isInfoVisible.value = true
            setTimeout(() => {
                impressumInfoEl.value?.scrollIntoView({ behavior: 'smooth' })
            }, 100)
        }

        const hideIpressumInfo = () => {
            isInfoVisible.value = false
        }

        const setImpressumValues = () => {
            isImpressumEnabled.value = store.state.config.show_impressum
            impressumDetails.value = store.state.config.impressum_details
        }

        onMounted(() => {
            setImpressumValues()
        })

        return {
            isImpressumEnabled,
            isInfoVisible,
            impressumInfoEl,
            impressumDetails,
            showImpressumInfo,
            hideIpressumInfo,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.impressum {
    flex-direction: column;

    .action-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: rem(14px);
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 10px;
        .impressume-button-link {
            background: none;
            border: none;
            text-decoration: underline;
            cursor: pointer;
            padding-bottom: 10px;
        }

        .action-icon {
            animation: down 2s ease infinite;
        }

        @keyframes down {
            0% {
                transform: translate(0);
            }

            20% {
                transform: translateY(10px);
            }

            40% {
                transform: translate(0);
            }
        }

        @-webkit-keyframes down {
            0% {
                transform: translate(0);
            }

            20% {
                transform: translateY(10px);
            }

            40% {
                transform: translate(0);
            }
        }
    }

    .info-wrapper {
        margin-top: 1.5em;
        width: 100%;
        border: 1px solid var(--tqs-input-border-color);
        border-radius: 10px;
        padding: 0em 1.5em 1.5em 1.5em;
        .info {
            font-size: rem(13px);
            padding: 5px;
        }

        .move-up {
            margin-top: 10px;
            width: 100%;
            text-align: right;
            i {
                cursor: pointer;
            }
        }
    }
}
</style>
