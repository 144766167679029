<template>
    <div>
        <div
            class="form-msg-wrapper"
            :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']">
            <p>{{ formErrorContent }}</p>
        </div>
        <div v-if="isNewUser" class="mb-2">
            <h2 class="title">
                {{ $t('password_reset.create_title') }}
            </h2>
            <div class="description">
                <div class="mb-2">
                    {{ $t('password_reset.welcome') }}
                </div>
                <div>
                    {{ $t('password_reset.setup_password') }}
                </div>
            </div>
        </div>
        <div v-else>
            <h2 class="title">
                {{ $t('password_reset.reset_title') }}
            </h2>
            <p class="description">
                {{ $t('password_reset.identity_verified') }}<br />{{
                    $t('password_reset.enter_new_password')
                }}
            </p>
        </div>

        <form @submit.prevent="createRecaptchaToken">
            <div class="field">
                <span class="p-input-icon-right w-full">
                    <i
                        class="password-info-tip pi pi-info-circle"
                        v-tooltip.top="$t('miscellaneous.tool_tips.password_pattern')"></i>
                    <input
                        id="password"
                        type="password"
                        class="inputfield w-full"
                        :class="[
                            v$.PASSWORD.$dirty && v$.PASSWORD.required.$invalid || serverErrors.password?.length ? 'error' : '',
                        ]"
                        :placeholder="$t('password_reset.your_new_password')"
                        v-model="RESET_PASSWORD_FORM.PASSWORD"
                        @keyup="v$.PASSWORD.$validate" />
                </span>
                <span v-if="passwordErrorMessage" class="form-error">{{ passwordErrorMessage }}</span>
            </div>
            <div class="field">
                <input
                    id="repassword"
                    type="password"
                    class="inputfield w-full"
                    :class="[
                        v$.REPASSWORD.$dirty && v$.REPASSWORD.required.$invalid ? 'error' : '',
                    ]"
                    :placeholder="$t('password_reset.retype_new_password')"
                    v-model="RESET_PASSWORD_FORM.REPASSWORD"
                    @keyup="v$.REPASSWORD.$validate" />
                <span v-if="rePasswordErrorMessage" class="form-error">{{ rePasswordErrorMessage }}</span>
            </div>
            <div class="field login-btn">
                <ButtonPV
                    :label="isNewUser
                        ? $t('password_reset.create_button')
                        : $t('password_reset.reset_button')
                        "
                    class="p-button-raised w-full primary"
                    type="submit"
                    :disabled="v$.$invalid" />
                <div class="sub-text mt-2" v-if="isNewUser">
                    {{ $t('password_reset.start_account') }}
                </div>
            </div>
            <router-link to="/portal/auth/login" class="forgot-password">{{
                $t('password_reset.back_to_login')
            }}</router-link>
        </form>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref } from 'vue'
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import NProgress from 'nprogress'
import { useI18n } from 'vue3-i18n'
import { useReCaptcha } from 'vue-recaptcha-v3'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'
import { ResetPasswordVerify } from '@/end-user-portal/dto/resetPassword'
import utils from '@/end-user-portal/shared/mixins/utils'

export default defineComponent({
    setup() {
        const reCaptcha = useReCaptcha()
        const executeRecaptcha = reCaptcha?.executeRecaptcha
        const recaptchaLoaded = reCaptcha?.recaptchaLoaded
        const { t } = useI18n()
        const isError = ref(false)
        const isFormMsg = ref(false)
        const formErrorContent = ref('')
        const isNewUser = ref(!!router.currentRoute.value.query.is_user_creation)

        const serverErrors: Ref<{ [s: string]: string[] }> = ref({})

        const passwordRef = computed(() => RESET_PASSWORD_FORM.value.PASSWORD)

        const RESET_PASSWORD_FORM = ref({
            PASSWORD: '',
            REPASSWORD: '',
        })

        const rules = {
            PASSWORD: {
                required,
                minLength: minLength(8),
                containsPasswordRequirement: helpers.withMessage(
                    () => t('password_reset.new_password_pattern_msg'),
                    (value: string) => utils.validateFields(value, 'password')
                ),
            },
            REPASSWORD: { required, sameAsPassword: sameAs(passwordRef) },
        }

        const v$ = useVuelidate(rules, RESET_PASSWORD_FORM)

        const passwordErrorMessage = computed(() => {
            if (!v$.value.PASSWORD.$dirty) return ''

            if (v$.value.PASSWORD.required.$invalid) {
                return t('password_reset.new_password_required_msg')
            }
            if (v$.value.PASSWORD.containsPasswordRequirement.$invalid) {
                return t('password_reset.new_password_pattern_msg')
            }

            if (v$.value.PASSWORD.minLength.$invalid) {
                return t('password_reset.new_password_eight_characters_msg')
            }
            if (serverErrors.value.password?.length) {
                return serverErrors.value.password[0]
            }

            return ''
        })

        const rePasswordErrorMessage = computed(() => {
            if (!v$.value.REPASSWORD.$dirty) return ''
            if (v$.value.REPASSWORD.required.$invalid) {
                return t('password_reset.retype_new_required_msg')
            }

            if (v$.value.REPASSWORD.sameAsPassword.$invalid) {
                return t('password_reset.retype_new_match_msg')
            }

            return ''
        })


        const completeResetPassword = (
            responseData: ResetPasswordVerify,
            recaptchaToken: string
        ) => {
            serverErrors.value = {}
            const data = {
                password: RESET_PASSWORD_FORM.value.PASSWORD,
                password_again: RESET_PASSWORD_FORM.value.REPASSWORD,
                token: responseData.token,
                uidb64: responseData.uidb64,
                recaptcha: recaptchaToken,
            }
            AuthenticationService.resetPasswordComplete(data)
                .then((response) => {
                    if (response?.data?.message === 'Password reset success') {
                        isError.value = false
                        isFormMsg.value = true
                        formErrorContent.value = t('password_reset.reset_success')
                        setTimeout(() => {
                            localStorage.clear()
                            router.push({ name: 'LoginView' })
                        }, 2500)
                    }
                })
                .catch((error) => {
                    isError.value = true
                    isFormMsg.value = true
                    // assign the error response to serverErrors
                    if (error.response.data?.password) {
                        serverErrors.value = error.response.data
                    }
                    formErrorContent.value = t('toast_messages.something_went_wrong')
                    console.error(error)
                })
        }

        const submitResetPassword = async (recaptchaToken: string) => {
            const token = router.currentRoute.value.query.token
            const id = router.currentRoute.value.query.id
            if (token && id)
                AuthenticationService.resetPasswordVerify(token, id)
                    .then((response) => {
                        if (response?.data?.message === 'Credentials Valid') {
                            completeResetPassword(response?.data, recaptchaToken)
                        }
                    })
                    .catch((error) => {
                        NProgress.done()
                        if (error.response.status === 500) {
                            isError.value = true
                            isFormMsg.value = true
                            formErrorContent.value = t('toast_messages.something_went_wrong')
                        }
                        console.error(error)
                    })
        }

        // if the recaptcha token created successfully, triger the reset password API with token attached
        const getRecaptchaVerified = async (token: string) => {
            try {
                submitResetPassword(token)
            } catch (err) {
                console.log('Error from recaptcha', err)
            }
        }

        const recaptcha = async () => {
            if (recaptchaLoaded) await recaptchaLoaded()
            if (executeRecaptcha) {
                const token = await executeRecaptcha('login')
                return token
            } else return ''
        }

        // if the form is valid, creating recaptcha token
        const createRecaptchaToken = async () => {
            v$.value.$validate()
            if (v$.value.$invalid) {
                return
            }
            const token = await recaptcha()
            if (token) await getRecaptchaVerified(token)
        }

        return {
            createRecaptchaToken,
            RESET_PASSWORD_FORM,
            isFormMsg,
            formErrorContent,
            isError,
            isNewUser,
            v$,
            serverErrors,
            passwordErrorMessage,
            rePasswordErrorMessage
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
@import '@/end-user-portal/assets/styles/login';
</style>
