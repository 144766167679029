<template>
    <div class="header-wrapper">
        <div class="menu-toggle" @click.stop="toggleMenu">
            <i class="pi pi-bars text-xl"></i>
        </div>
        <div class="breadcrumb-wrapper">
            <BreadcrumbSection :items="breadcrumbs" />
        </div>
        <div class="profile-wrapper">
            <div class="user">
                <span class="text">{{ $t('miscellaneous.welcome') }}, {{ fullUserName }}</span>
                <!-- display avatar with initials -->
                <span class="name" :title="fullUserName">
                    <AvatarPV
                        :label="firstLetterOfFirstName + '' + firstLetterOfLastName"
                        class="ml-2"
                        style="background-color: #0c2340"
                        shape="circle"
                /></span>

                <span class="logout">
                    <i class="pi pi-sign-out" @click="logout()" title="Logout"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, ref, defineComponent, watch, ComputedRef, computed } from 'vue'
import store from '@/store'
import { Profile } from '@/end-user-portal/dto/profile'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import router from '@/router'
import BreadcrumbSection from './BreadcrumbSection.vue'
import { useI18n } from 'vue3-i18n'
import { RouteLocationNormalized, useRoute } from 'vue-router'
import variables from '@/client-app/shared/variables'

export default defineComponent({
    setup() {
        const { t } = useI18n()

        const route: RouteLocationNormalized = useRoute()
        // remove first element is empty and second path is static 'portal'
        const pathArray = computed(() => route.path.split('/').slice(2))

        const breadcrumbs: ComputedRef<{ url: string; label: string }[]> = computed(() => {
            return pathArray.value.reduce(
                (
                    breadcrumbArray: { url: string; label: string; path: string }[],
                    path: string,
                    idx: number
                ) => {
                    breadcrumbArray.push({
                        path: path,
                        url: breadcrumbArray[idx - 1]
                            ? '/' + breadcrumbArray[idx - 1].path + '/' + path
                            : '/portal/' + path,
                        // ignore first two element
                        label: t(route.matched[idx + 2]?.meta?.langKey as string),
                    })
                    return breadcrumbArray
                },
                []
            )
        })

        const firstLetterOfFirstName = ref('')
        const firstLetterOfLastName = ref('')
        const fullUserName = ref('')
        const profileDetails: Ref<Profile> = ref(store.state.profileDetails)
        const setAvatarDetails = () => {
            if (!profileDetails.value) return
            firstLetterOfFirstName.value = profileDetails.value.first_name.charAt(0)
            firstLetterOfLastName.value = profileDetails.value.last_name.charAt(0)
            fullUserName.value = `${profileDetails.value.first_name} ${profileDetails.value.last_name}`
        }

        // get and set breadcrumb values
        // const breadcrumbs = ref([{ label: t('registrations.title'), url: '' }])

        // when click on logout button - call for logout API and if it is success clearing all local storages
        const logout = () => {
            const refreshToken = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN)
                ? localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN)
                : ''
            const data = { refresh: refreshToken }
            AuthenticationService.clearAuthentication(data)
                .then(() => {
                    localStorage.clear()
                    router.push({ name: 'LoginView' })
                    // to update 'assume_role' value to null in every api request
                    window.location.reload()
                })
                .catch((error) => {
                    throw Error(error)
                })
        }

        // Toggle menu open close state
        const toggleMenu = () => {
            store.commit('SET_MENU_IS_OPEN', !store.state.menuIsOpened)
        }

        // watch for subpartners api response and set sub partner dropdown values
        watch(
            () => store.state.profileDetails,
            () => {
                if (store.state.profileDetails) {
                    profileDetails.value = store.state.profileDetails
                    setAvatarDetails()
                }
            },
            {
                immediate: true,
            }
        )
        return {
            breadcrumbs,
            firstLetterOfFirstName,
            firstLetterOfLastName,
            fullUserName,
            logout,
            toggleMenu,
        }
    },
    components: { BreadcrumbSection },
})
</script>

<style lang="scss">
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.header-wrapper {
    display: flex;
    justify-content: space-between;
    .breadcrumb-wrapper {
        margin-right: 5px;
    }
    .profile-wrapper {
        margin-left: auto;
        padding: 5px 0px 5px;
        .user {
            color: $white;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: rem(14px);
        }
    }
    .logout {
        padding-left: rem(14px);
        cursor: pointer;
    }
    .menu-toggle {
        display: none;
        color: $white;
        margin-right: 8px;
        cursor: pointer;
        i {
            padding: 2px;
            margin-left: -2px;
        }
    }
    @include respond-to('max-small') {
        .profile-wrapper {
            .user {
                cursor: default;
                .text {
                    display: none;
                }
            }
        }
    }
    @include respond-to('max-xs-small') {
        .menu-toggle {
            display: inline-block;
        }
    }
}
</style>
