<!-- Deactivate page content to be shown when the frontend is deactivated -->

<template>
    <div class="frontend-deactivated">
        <div class="title-text">THG-Quoten Service</div>
        <p>Diese Website ist nicht aktiv.</p>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        localStorage.clear()
        return {
            detail: store.state.config?.detail,
        }
    },
})
</script>

<style scoped lang="scss">
.frontend-deactivated {
    text-align: center;
}
</style>
