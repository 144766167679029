<template>
    <div>
        <div v-if="showTutorialSteps" class="view-root">
            <div :class="utils.getTitleClass()">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                So scannen Sie Ihren Fahrzeugschein: Vorderseite
            </div>

            <!-- carousel section -->
            <div class="carousel-wrapper">
                <FlickityPV ref="flickityEl" :options="flickityOptions">
                    <div class="slider-vdo">
                        <video class="video" playsInline muted loop autoplay>
                            <source src="@/client-app/assets/vid1.mp4" type="video/mp4" />
                        </video>

                        <div class="carousel-title">Schritt 1</div>
                        <div class="carousel-sub-title">
                            Falten Sie Ihren Fahrzeugschein wie in der Animation gezeigt auf.
                        </div>
                    </div>

                    <div class="slider-vdo">
                        <video class="video" playsInline muted loop autoplay>
                            <source src="@/client-app/assets/vid2.mp4" type="video/mp4" />
                        </video>
                        <div class="carousel-title">Schritt 2</div>
                        <div class="carousel-sub-title">
                            Der Fahrzeugschein muss gut sichtbar sein und vollständig auf einer
                            flachen Oberfläche aufliegen.
                        </div>
                    </div>

                    <div guid="99" class="slider-vdo">
                        <video class="video" playsInline muted loop autoplay>
                            <source src="@/client-app/assets/vid3.mp4" type="video/mp4" />
                        </video>
                        <div class="carousel-title">Schritt 3</div>
                        <div class="carousel-sub-title">
                            Gestatten Sie uns falls nötig Zugriff auf Ihre Kamera für die Aufnahme.
                            Positionieren Sie den Fahrzeugschein im Rahmen und drücken den Auslöser.
                        </div>
                    </div>

                    <div id="finalslide" class="slider-vdo">
                        <video class="video" playsInline muted loop autoplay>
                            <source src="@/client-app/assets/vid4.mp4" type="video/mp4" />
                        </video>
                        <div class="carousel-title">Schritt 4</div>
                        <div class="carousel-sub-title">
                            Bei klar erkennbarem, vollständigen Fahrzeugschein bestätigen Sie. Bei
                            unklarer Aufnahme wiederholen Sie den Prozess.
                        </div>
                    </div>
                </FlickityPV>
            </div>

            <div v-if="showNextBtn" class="action-wrapper mt-5">
                <ButtonPV label="Nächster Schritt" class="primary w-full" @click="onNext()" />
            </div>
        </div>

        <div v-if="showUploadAfterFail">
            <div class="title-text">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                Fahrzeugschein hochladen
            </div>
            <div v-if="error" class="p-error mt-3 mb-3">
                {{ errorMessage }}
            </div>

            <div>Laden Sie eine Aufnahme aus Ihrer Smartphone-Galerie hoch.</div>
            <div class="action-wrapper mt-5">
                <label class="inputlabel" ref="labelEl">
                    <span><i class="far fa-image mr-2"></i>Hochladen</span>
                    <input
                        id="fileinput"
                        @change="photoAdded"
                        type="file"
                        accept="image/*, application/pdf"
                    />
                </label>
            </div>
            <br />
            <div>Wiederholen Sie den Vorgang.</div>
        </div>
        <div v-if="showScan" class="action-wrapper mt-5">
            <label class="inputlabel" ref="labelEl" @click="captureClick">
                <span><i class="fas fa-camera mr-2"></i>Scan starten</span>
                <input
                    id="captureinput"
                    @change="photoAdded"
                    type="file"
                    capture="environment"
                    accept="image/*"
                />
            </label>
        </div>

        <!-- cancel multiple zlb upload -->
        <div class="action-wrapper mt-3" v-if="isBevRegCreated">
            <ButtonPV
                class="secondary w-full"
                @click="goBackToValidationBackside()"
                label="Abbrechen und Registrierung fortführen"
            />
        </div>
        <!-- loading spinner -->
        <VueLoading :opacity="0.8" :active="loading" :can-cancel="true" color="#778da0">
            <div class="loading-text">Wird bearbeitet...</div>
        </VueLoading>
    </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, onMounted, onUpdated, computed } from 'vue'
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'

export default defineComponent({
    props: {
        showDot: Boolean,
        error: Boolean,
        errorMessage: String,
        loading: Boolean,
        isBevRegCreated: Boolean,
    },
    setup(props, { emit }) {
        const showNextBtn = ref(true)
        const showCaptureBtn = ref(false)
        const resultMode = ref(false)
        const flickityEl: Ref = ref(null)
        const flickityOptions = { prevNextButtons: false }

        onMounted(() => {
            flickityEl.value?.$flickity.on('change', onFlickityChange)
        })

        // fire when component updates
        onUpdated(() => {
            flickityEl.value && flickityEl.value.$flickity.on('change', onFlickityChange)
        })

        const onFlickityChange = (index: number) => {
            showNextBtn.value = index !== 3
            showCaptureBtn.value = index === 3
        }

        const photoAdded = (evt: Event) => {
            emit('photoAdded', evt)
        }

        const onNext = () => {
            flickityEl.value?.flickity().next()
            flickityEl.value?.$flickity.on('change', onFlickityChange)
        }

        const captureClick = () => {
            setTimeout(() => {
                showCaptureBtn.value = false
                resultMode.value = true
            }, 1000)
        }

        // go back to backside validation page when user cancel
        const goBackToValidationBackside = () => {
            emit('go-back-to-validation')
        }

        /* when user is on result page after done with carousel,
        enable go view carousels when click on navigation back button on browser*/
        window.addEventListener(
            'popstate',
            () => {
                // The popstate event is fired each time when the current history entry changes.
                if (resultMode.value) {
                    resultMode.value = false
                    showCaptureBtn.value = false
                    showNextBtn.value = true
                }
            },
            false
        )

        // Boolean value to check when the Tutorial steps are shown
        const showTutorialSteps = computed(() => {
            return !resultMode.value && !props.loading && !props.isBevRegCreated
        })

        /* Boolean value to check when to show the 
        upload option after the first scan fails. */
        const showUploadAfterFail = computed(() => {
            return (resultMode.value && !props.loading) || props.isBevRegCreated
        })

        // Boolean value to check when to show the scan button
        const showScan = computed(() => {
            return (
                showCaptureBtn.value ||
                (resultMode.value && !props.loading) ||
                !!props.isBevRegCreated
            )
        })

        return {
            utils,
            router,
            showNextBtn,
            showCaptureBtn,
            resultMode,
            onNext,
            flickityEl,
            flickityOptions,
            photoAdded,
            captureClick,
            goBackToValidationBackside,
            showTutorialSteps,
            showUploadAfterFail,
            showScan,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.carousel-wrapper {
    margin-top: rem(32px);
    margin-bottom: rem(64px);
    .slider-vdo {
        display: flex;
        width: 100%;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        .video {
            height: 400px;
        }
        .carousel-title {
            font-size: rem(20px);
            text-align: center;
            margin-bottom: 5px;
            margin-top: 10px;
        }
        .carousel-sub-title {
            font-size: rem(16px);
            text-align: center;
        }
    }
}

#captureinput {
    display: none;
}

#fileinput {
    display: none;
}

.processing {
    font-size: rem(28px);
}
.p-component {
    font-family: var(--tqs-font-family);
}
</style>
