<template>
    <div class="welcome desktop view-root">
        <!-- title-text is a common class for all the title -->
        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            <span v-html="conf.title"></span>
        </div>
        <p id="registrieren">
            {{ conf.register }}
        </p>
        <div class="list-section">
            <div class="sub-text">{{ conf.four_steps }}</div>
            <ul class="list">
                <li>
                    <span class="order-number mr-2">1</span>
                    <div class="listtext">
                        {{ conf.step1 }}
                    </div>
                </li>
                <li>
                    <span class="order-number mr-2">2</span>
                    <div class="listtext">
                        {{ conf.step2 }}
                    </div>
                </li>
                <li>
                    <span class="order-number mr-2">3</span>
                    <div class="listtext">
                        {{ conf.step3 }}
                    </div>
                </li>
                <li>
                    <span class="order-number mr-2">4</span>
                    <div class="listtext">
                        {{ conf.step4 }}
                    </div>
                </li>
            </ul>
        </div>
        <div class="action-wrapper">
            <ButtonPV
                label="Start"
                @click="router.push({ name: 'TermsView', query: router.currentRoute.value.query })"
                class="primary w-full mb-2"
                id="welcome-desktop-start"
            />
            <div
                v-if="by_thg_quoten_gmbh"
                class="faq-nav"
                @click="router.push({ name: 'FAQ', query: router.currentRoute.value.query })"
            >
                <a href=""> Was steckt dahinter? </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from '@/router'
import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'

export default defineComponent({
    setup() {
        return {
            router,
            showDot: store.state.config.display_title_dot,
            conf: store.state.config.pages.welcome,
            by_thg_quoten_gmbh: store.state.config.by_thg_quoten_gmbh,
            utils,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.welcome {
    .sub-text {
        font-weight: bold;
        line-height: 1.5 !important;
    }

    .fa-circle {
        font-size: rem(16px) !important;
        color: var(--tqs-circle-color);
    }
    .list-section {
        .list {
            padding-left: 0;
            text-align: left;
            li {
                display: flex;
                list-style: none;
                font-size: rem(14px);
                padding: 10px 0;
                line-height: 1.5;
                .order-number {
                    color: var(--tqs-circle-inner-text);
                    background-color: var(--tqs-circle-color);
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    padding: 4px 10px;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .listtext {
                    margin-left: rem(8px);
                }
            }
        }
    }

    .action-wrapper {
        text-align: center;
        .faq-nav {
            font-size: rem(10px);
        }
    }
}
.p-component {
    font-family: var(--tqs-font-family);
}

#wrapper {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
