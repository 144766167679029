<template>
    <div id="desktop">
        <div class="title-text">Verifiziert</div>
        <div class="action-wrapper mt-5">
            <ButtonPV
                label="Weiter auf Desktop-Computer"
                @click="submit()"
                type="submit"
                class="primary w-full"
            />
        </div>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'
import router from '@/router'

export default defineComponent({
    setup() {
        const submit = () => {
            const b2b_suffix = router.currentRoute.value.fullPath.includes('/b2b') ? 'B2B' : ''
            router.push({
                name: 'ImageUploadWrapper' + b2b_suffix,
                query: router.currentRoute.value?.query,
            })
        }

        return {
            showDot: store.state.config.display_title_dot,
            submit,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.action-wrapper {
    margin: auto;
    width: 50%;
    text-align: center;
}
#desktop {
    display: 'flex';
    justify-content: 'center';
    text-align: center;
}

#qrCode {
    display: inline-block;
    color: var(--tqs-button-color-left);
}
.Button {
    width: 50vw;
}
.checkboxes {
    text-align: left;
}

.action-wrapper {
    text-align: center;
}

.field-checkbox {
    margin-top: rem(14px);
    margin-bottom: 0px;
}
.p-component {
    font-family: var(--tqs-font-family);
}
.welcome {
    .sub-text {
        font-weight: bold;
        line-height: 1.5 !important;
    }

    .fa-circle {
        font-size: rem(16px) !important;
        color: var(--tqs-circle-color);
    }
    .list-section {
        .list {
            padding-left: 0;
            text-align: left;
            li {
                display: flex;
                list-style: none;
                font-size: rem(14px);
                padding: 10px 0;
                line-height: 1.5;
                .order-number {
                    color: var(--tqs-circle-inner-text);
                    background-color: var(--tqs-circle-color);
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    padding: 4px 10px;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .listtext {
                    margin-left: rem(7px);
                }
            }
        }
    }

    .action-wrapper {
        text-align: center;
        .faq-nav {
            font-size: rem(12px);
        }
    }

    .impressum {
        display: flex;
        width: 100%;
        .impressum-left {
            flex: 1;
        }
    }
}
.p-component {
    font-family: var(--tqs-font-family);
}

#wrapper {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
