import { createI18n } from 'vue3-i18n'
import englishMessages from './en-GB.json'
import dutchMessage from './de-DE.json'

const i18n = createI18n({
    locale: process.env.VUE_APP_LANGUAGE, // set locale
    messages: {
        en: {
            ...englishMessages,
        },
        de: {
            ...dutchMessage,
        },
    },
})

export default i18n
