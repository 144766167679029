const LOCAL_STORAGE_ITEMS = {
    AUTHENTICATED: 'authenticated',
    // USER: 'user',
    CONTRACT: 'contract',
    FRONT_IMAGE_SUCSESS: 'front-image-success',
    BACK_IMAGE_SUCSESS: 'back-image-success',
    BILLING_SUCCESS: 'billing-success',
    TC_AND_PP: 'tc-and-pp',
    TC_AND_PP_CHECKED: 'tc-and-pp-checked',
    ENERCITY: 'true',
    CAMPAIGN: 'campaign',
    FREE_TEXT_CHECKED: 'free-text-checked',
    URL_VARIABLE_VALUE: 'url-variable_value',
    CHARGE_CARD_ID: 'charge-card-id',
    RETURN_URL: 'return-url',
    QUOTE_ID: 'quote-id',
    QUOTE_UUID: ' quote-uuid',
    ROUTERPATH: 'upload-fullpath',
    BEVREG_ID: 'bevRegistrationId',
    BEV_REGISTRATION_COMPLETED: 'bev-registration-completed',
    REFRESH_TOKEN: 'refresh',
    RESUBMIT_TNC: 'resubmit-tnc',
}
const WALLBOX_PARTNERS = {
    EON_WALLBOX: 'eon-wallbox',
    EON_WALLBOXBK: 'eon-wallboxbk',
}

const URLS = {
    login_email: '/auth/registration/email/',
    login_otp: '/auth/registration/otp/',
    return_url_verify: '/return_url/verify/',
    verify_opt: '/auth/registration/otp/verify/',
    verify_email: '/auth/registration/email/verify/',
    system_constraints: '/system_constraints/',
    fe_user_agent_data: '/fe_user_agent_data/',
    bev_registrations: '/bev_registrations/',
    clients: '/clients/',
    vat_validation: '/clients/validate_vat_number/',
}

export default { LOCAL_STORAGE_ITEMS, WALLBOX_PARTNERS, URLS }
