<template>
    <div class="registration-wrapper">
        <div class="advert" v-if="advertisement && advertisement?.enabled">
            <div class="advert__wrap">
                <a
                    v-if="advertisement.type === 'image'"
                    :href="advertisement.url"
                    target="_blank"
                    @click="clickedAdvert(Number(advertisement.id))"
                >
                    <div class="advert__img">
                        <img :src="advertisement.image" />
                    </div>
                </a>
                <div v-if="advertisement.type === 'freetext'" class="advert__text">
                    <div v-html="advertisement?.freetext"></div>
                </div>
                <div class="advert__notice">{{ $t('registrations.advertisement') }}</div>
            </div>
        </div>
        <div class="body-container">
            <div class="registration-header grid">
                <div class="right-block col-12 sm:col-4 w-full">
                    <div class="flex justify-content-end">
                        <ButtonPV
                            :label="$t('registrations.new_registration')"
                            class="primary"
                            @click="goToRegistration()"
                        />
                    </div>
                </div>
            </div>
            <div class="registrations-body">
                <div
                    class="component-list-wrapper registration-blocks-wrapper"
                    v-if="events.length > 0"
                >
                    <div
                        v-for="(item, index) in events"
                        :key="index"
                        class="mb-3"
                        :id="`section-${item.sequence}`"
                    >
                        <RegistrationBlock
                            :item="item"
                            :events="events"
                            @resubmit-registration="resubmitRegistration"
                            @go-to-page="goToPage"
                            :pageNumber="pagination.page"
                        />
                    </div>

                    <div
                        class="paginater-wrapper"
                        v-if="pagination.total > 0 && events?.length > 0"
                    >
                        <PaginatorPV
                            class="qa-pagination"
                            :rows="pagination.limit"
                            :totalRecords="pagination.total"
                            :first="(pagination.page - 1) * pagination.limit"
                            @page="onPaginationChange($event)"
                        ></PaginatorPV>
                    </div>
                </div>
                <div class="no-data" v-if="events?.length === 0 && !loading">
                    <MessagePV severity="info" :closable="false"
                        ><span v-html="$t('registrations.no_data')"></span
                    ></MessagePV>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import RegistrationBlock from '@/end-user-portal/shared/components/registrations/RegistrationBlock.vue'
import RegistrationService from '@/end-user-portal/shared/services/Registrations'
import router from '@/router'
import { PageState } from 'primevue/paginator'
import store from '@/store'
import { TermsAndConditionsDTO } from '@/shared/dto/CommonDTOs'

export default defineComponent({
    components: { RegistrationBlock },
    setup() {
        const pagination = ref({
            page: 1,
            limit: 5,
            total: 0,
        })
        const loading = ref(false)

        const advertisement = computed(() => store.state.config?.partneradvertisement)

        const onPaginationChange = (event: PageState) => {
            pagination.value.page = event.page + 1
        }

        const events: Ref = ref([])

        const getQAList = async () => {
            loading.value = true
            return RegistrationService.getQAList(pagination.value.page, pagination.value.limit)
                .then((response) => {
                    events.value = response.data.results.sort(
                        (a: { sequence: string }, b: { sequence: string }) => {
                            return a.sequence > b.sequence
                        }
                    )
                    pagination.value.total = response.data.count
                    loading.value = false
                })
                .catch((err) => {
                    loading.value = false
                    if (err.response.status === 403) {
                        router.push({
                            name: 'SignupView',
                        })
                        console.error('new user found')
                    }
                    console.error('Error has occured while getting qa list')
                })
        }

        const goToRegistration = () => {
            router.push('/')
        }

        const resubmitRegistration = (params: {
            selectedYear: number
            id: number
            formData: TermsAndConditionsDTO
        }) => {
            const data = {
                period: params.selectedYear,
                email_promotion_opt_in: params.formData.EMAIL_PROMOTION,
                free_text: params.formData.ADDITIONAL_REQUIREMENT,
            }
            RegistrationService.resubmitRegistration(params.id, data)
                .then(() => {
                    const toasterProps = {
                        showToaster: true,
                        isSuccess: true,
                        toasterContent: 'Registration is created',
                    }
                    store.commit('SET_TOASTER_VALUES', toasterProps)
                    // If registration success update the list
                    getQAList()
                })
                .catch((err) => {
                    const toasterProps = {
                        showToaster: true,
                        isSuccess: false,
                        toasterContent: 'Vehicle id already exist for this period',
                    }
                    store.commit('SET_TOASTER_VALUES', toasterProps)
                    console.error('error', err)
                })
        }

        /* when user navigates to the registrations page after user has entered the terms selections in popup,
        scroll to the particular QA*/
        const goToPage = (selectedSequence: string) => {
            // Get the sequence of the section
            const targetIndex = selectedSequence // Example: Scroll to the second section

            // Construct the ID based on the sequence
            const targetId = `section-${targetIndex}`

            // Get the element by ID
            const targetElement = document.getElementById(targetId)

            // Check if the element exists and scroll to it
            if (targetElement) {
                document
                    .getElementsByClassName('bottom-wrapper')[0]
                    .scrollTo({ top: targetElement.offsetTop - 82, behavior: 'smooth' })
            }
        }

        const clickedAdvert = (ad_id: number) => {
            RegistrationService.setAdvertClick(ad_id).catch((error) => console.error(error))
        }

        onMounted(async () => {
            if (
                store.state.resubmitPopupDetailsEUP &&
                store.state.resubmitPopupDetailsEUP.resubmitData
            ) {
                pagination.value.page = store.state.resubmitPopupDetailsEUP?.pageNumber
                await getQAList()
                // added a small delay until the DOM is getting ready
                setTimeout(() => {
                    goToPage(store.state.resubmitPopupDetailsEUP?.resubmitData?.sequence)
                }, 100)
            }
        })

        // Get QA List when pagination page changes
        watch(
            () => pagination.value.page,
            () => {
                getQAList()
            }
        )

        onMounted(() => {
            getQAList()
        })

        return {
            events,
            pagination,
            advertisement,
            onPaginationChange,
            goToRegistration,
            resubmitRegistration,
            loading,
            goToPage,
            clickedAdvert,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.no-data {
    color: $medium-grey;
}

.body-container {
    margin: 0 -20px;
    padding: 20px;
    background-color: $white;
}

.advert {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    &__text {
        font-size: rem(16px);
        pointer-events: none;
    }
    &__img {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        img {
            object-fit: contain;
            width: 100%;
        }
    }
    &__notice {
        position: absolute;
        color: #888;
        right: 25px;
        bottom: -2px;
        background-color: #fff;
        font-size: rem(12px);
        font-style: italic;
        padding: 5px;
    }
    :deep(h1) {
        font-size: rem(24px);
    }
    :deep(iframe) {
        width: 100%;
        border: 0;
        height: 30vw;
    }
    @include respond-to('max-medium') {
        &__text {
            font-size: rem(14px);
        }
        :deep(iframe) {
            height: 42vw;
        }
        :deep(h1) {
            font-size: rem(18px);
        }
        &__notice {
            font-size: rem(9px);
            font-style: italic;
            padding: 1px 4px 2px;
        }
    }
}

.right-block {
    margin-bottom: 12px;
}
@media (max-width: 576px) {
    .left-block {
        > .grid {
            margin-top: 0;
            animation: expand 0.5s ease-out;
        }
        &.is-hidden {
            animation: hide 1s;
            padding-top: 0;
            padding-bottom: 0;
            > .grid {
                margin-top: -100%;
                animation: collapse 0.5s ease-in;
            }
        }
    }
}
@keyframes hide {
    from {
        display: block;
    }
    to {
        display: none;
    }
}
@keyframes expand {
    from {
        margin-top: -100%;
    }
    to {
        margin-top: 0%;
    }
}

@keyframes collapse {
    from {
        margin-top: 0%;
    }
    to {
        margin-top: -100%;
    }
}
</style>
