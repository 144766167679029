<template>
    <div class="breadcrumb-wrapper-inner">
        <div class="item" v-for="(item, index) in items" :key="index">
            <router-link v-if="item.url" :to="item.url">
                {{ item.label }}
            </router-link>
            <p v-else>{{ item.label }}</p>
            <span v-if="index < items.length - 1">/</span>
        </div>
    </div>
</template>

<script lang="ts">
interface BreadCrumbDTO {
    url: string
    label: string
}
export default {
    props: {
        items: {
            type: Array as () => Array<BreadCrumbDTO>,
            required: true,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.breadcrumb-wrapper-inner {
    display: flex;
    margin-right: 10px;
    padding: 5px 0 5px;
    .item {
        a,
        span,
        p {
            font-size: rem(22px);
            color: $white !important;
            font-weight: 800;
            text-shadow: 2px 2px 2px #464646;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0;
            letter-spacing: 1px;
            float: left;
        }
        span {
            display: inline-block;
            margin: 0 10px;
        }
        i.pi {
            font-size: rem(22px);
            font-weight: bold;
            bottom: -2px;
            position: relative;
            margin-right: 4px;
            line-height: 18px;
        }
    }
}
</style>
