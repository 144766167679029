<template>
    <div>
        <div class="validation view-root">
            <img src="@/client-app/assets/SuccessIcon.svg" id="checkmark" />
            <br />
            <h1>Fahrzeugschein erfolgreich registriert</h1>
            <p>Wir haben die relevanten Datenpunkte Ihres Fahrzeugscheins erfolgreich erfasst.</p>
            <ButtonPV
                label="Weiter"
                class="primary w-full mb-2"
                @click="submit()"
                id="validation-button"
            />
            <!-- avoid multiple bev registations for EON partners -->
            <ButtonPV
                v-if="!commonUtils.isEONWallbox"
                label="Weitere Fahrzeugscheine hochladen"
                @click="goToImageUpload()"
                class="secondary w-full"
                id="multiple-image-upload"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import router from '@/router'
import commonUtils from '@/shared/mixins/utils'
import variables from '@/client-app/shared/variables'
import store from '@/store'
import APIService from '@/client-app/shared/APIService'

export default defineComponent({
    /* Navigate only if there is at least one image uploaded */
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.FRONT_IMAGE_SUCSESS) !== 'true') {
            if (window.location.href.includes('/b2b')) {
                next({
                    name: 'ImageUploadWrapperB2B',
                    query: router.currentRoute.value?.query,
                })
            } else {
                next({
                    name: 'ImageUploadWrapper',
                    query: router.currentRoute.value?.query,
                })
            }
        } else {
            next()
        }
    },
    setup() {
        const loading = ref(false)
        const freeTextChecked = localStorage.getItem(
            variables.LOCAL_STORAGE_ITEMS.FREE_TEXT_CHECKED
        )
        const URLVariableValue =
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.URL_VARIABLE_VALUE) ||
            JSON.stringify(router.currentRoute.value.fullPath)
        // For some reason freeTextChecked is "undefined' as a string
        const freeText =
            freeTextChecked &&
            freeTextChecked !== 'undefined' &&
            JSON.parse(freeTextChecked) === 'true'
                ? true
                : false
        const error = ref(false)
        const errorMessage = ref('')
        const bevRegistrationId = store.state.bevRegistrationId

        // avoid navigating back from browser back button
        window.onpopstate = function () {
            history.go(1)
        }

        const goToImageUpload = () => {
            const fullPath = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.ROUTERPATH)

            // send `multi` to identify the image upload is not first time
            if (store.state.config.authentication_flow !== 'otp' && fullPath) {
                router.push(`${fullPath}`)
            } else {
                if (router.currentRoute.value.fullPath.includes('/b2b')) {
                    router.push({
                        name: 'ImageUploadWrapperB2B',
                    })
                } else {
                    router.push({
                        name: 'ImageUploadWrapper',
                    })
                }
            }
        }

        /* When response was successful we lock the object.
            After this, we cannot update this specific
            bevRegistration anymore. We do this to signal to the
            api that we are done with editing this specific object. */
        const lockRegistration = async () => {
            try {
                await APIService.imageLock(Number(bevRegistrationId))

                // store front image upload success to use in router
                localStorage.setItem(
                    variables.LOCAL_STORAGE_ITEMS.BEV_REGISTRATION_COMPLETED,
                    'true'
                )
            } catch {
                loading.value = false
                error.value = true
                errorMessage.value = 'Fehler beim Hochladen'
                throw Error('lock-failed')
            }
        }

        // lock submitting client info
        const lockClient = async (clientId: number) => {
            APIService.lockClient(clientId)
                .then(() => {
                    loading.value = false
                    localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.BILLING_SUCCESS, 'true')
                    router.push({ name: 'FinishB2B', query: router.currentRoute.value.query })
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Fehler beim Hochladen'
                    throw Error('client lock failed')
                })
        }

        const submit = async () => {
            await lockRegistration()

            // custom billing page for non-b2b access
            if (!router.currentRoute.value.fullPath.includes('/b2b')) {
                router.push({ name: 'Billing', query: router.currentRoute.value?.query })
                return
            }

            const chargeCardId =
                localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.CHARGE_CARD_ID) ||
                JSON.stringify(router.currentRoute.value.query.user_reference) ||
                ''
            const campaign =
                localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.CAMPAIGN) ||
                JSON.stringify(router.currentRoute.value.query.campaign) ||
                ''
            const data = {
                first_name: 'registration',
                last_name: 'b2b2c',
                free_text: freeText,
                charge_card: chargeCardId ? JSON.parse(chargeCardId) : '',
                campaign: campaign ? JSON.parse(campaign) : '',
                full_url_variable_string: URLVariableValue ? JSON.parse(URLVariableValue) : '',
                source: 'b2b_fe',
            }

            loading.value = true
            return APIService.billingSubmit(data)
                .then((response) => {
                    /* When response was successful we lock the object.
                    After this, we cannot update this specific client
                    anymore. We do this to signal to the api that we are
                    done with editing this specific object. */
                    const clientId = response.data.id
                    lockClient(clientId)
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Fehler beim Hochladen'
                    throw Error('submit failed')
                })
        }

        return {
            commonUtils,
            submit,
            goToImageUpload,
        }
    },
})
</script>

<style scoped lang="scss">
.validation {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

#checkmark {
    width: 30%;
}

.p-component {
    font-family: var(--tqs-font-family);
}
</style>
