import LoginView from '@/end-user-portal/views/auth/LoginView.vue'
import RegistrationsView from '@/end-user-portal/views/RegistrationsView.vue'
import { RouteRecordRaw } from 'vue-router'
import HomeView from '@/end-user-portal/views/HomeView.vue'
import SignupView from '@/end-user-portal/views/auth/SignupView.vue'
import commonVariables from '@/shared/Variables'
import ResetPassword from '@/end-user-portal/views/auth/ResetPassword.vue'
import EmailVerify from '@/end-user-portal/views/auth/EmailVerify.vue'
import AuthWrapper from '@/end-user-portal/views/auth/AuthWrapper.vue'
import TermsAndConditions from '@/end-user-portal/views/auth/TermsAndConditions.vue'
import ImpressumView from '@/end-user-portal/views/ImpressumView.vue'
import PrivacyDetailsView from '@/end-user-portal/views/PrivacyDetailsView.vue'
import RecaptchaOtpFlow from '@/end-user-portal/views/auth/RecaptchaOtpFlow.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/portal',
        component: () => import('@/end-user-portal/views/EndUserPortalWrapper.vue'),
        children: [
            {
                path: '',
                redirect: { name: 'LoginView' },
            },
            {
                path: 'auth',
                component: AuthWrapper,
                children: [
                    {
                        path: '',
                        redirect: { name: 'LoginView' },
                    },
                    {
                        path: 'login',
                        name: 'LoginView',
                        component: LoginView,
                    },
                    {
                        path: 'reset-password',
                        name: 'ResetPassword',
                        component: ResetPassword,
                    },
                    {
                        path: 'verify-email',
                        name: 'EmailVerify',
                        component: EmailVerify,
                    },
                    {
                        path: 'terms-and-conditions',
                        name: 'TermsAndConditions',
                        component: TermsAndConditions,
                    },
                    {
                        path: '/otp/:userId',
                        name: 'RecaptchaOtpFlow',
                        component: RecaptchaOtpFlow,
                        props: true,
                    },
                    {
                        path: 'signup',
                        name: 'SignupView',
                        component: SignupView,
                        beforeEnter: (to, from, next) => {
                            // allow access signup page when the query param key exists
                            if (to.query.key) {
                                next()
                            } else {
                                // avoid routing to signup page directly without the registered email
                                if (
                                    localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.EMAIL)
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            }
                        },
                        props: (route) => {
                            return {
                                key: route.query.key,
                            }
                        },
                    },
                ],
            },

            {
                path: '',
                component: HomeView,
                children: [
                    {
                        path: 'registrations',
                        name: 'RegistrationsView',
                        component: RegistrationsView,
                        meta: {
                            langKey: 'registrations.title', //crumb
                        },
                    },
                    {
                        path: 'impressum',
                        name: 'Impressum',
                        component: ImpressumView,
                        meta: {
                            langKey: 'menu.impressum', //crumb
                        },
                        beforeEnter: (to, from, next) => {
                            // Show page if show_impressum config is enabled and impressum_details text is not empty
                            if (
                                store.state.config.show_impressum &&
                                store.state.config.impressum_details
                            ) {
                                next()
                            } else {
                                next(false)
                            }
                        },
                    },
                    {
                        path: 'data-privacy-info',
                        name: 'Data Privacy info',
                        component: PrivacyDetailsView,
                        meta: {
                            langKey: 'menu.data-privacy-info', //crumb
                        },
                        beforeEnter: (to, from, next) => {
                            // show page if show_data_privacy config is enabled and privacydetails text is not empty
                            if (
                                store.state.config.show_data_privacy &&
                                store.state.config.pages.privacydetails.text
                            ) {
                                next()
                            } else {
                                next(false)
                            }
                        },
                    },
                ],
                beforeEnter: (to, from, next) => {
                    const accessToken = localStorage.getItem(
                        commonVariables.LOCAL_STORAGE_ITEMS.TOKEN
                    )
                    if (accessToken) {
                        next()
                    } else {
                        next({ name: 'LoginView' })
                    }
                },
            },
        ],
    },
]

export default routes
