import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "breadcrumb-wrapper-inner" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        (item.url)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(item.label), 1)),
        (index < $props.items.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "/"))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}