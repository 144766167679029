import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34c0a2d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy view-root" }
const _hoisted_2 = { id: "termstext" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", { innerHTML: _ctx.text }, null, 8, _hoisted_3),
      _createElementVNode("p", null, [
        (_ctx.documentURL)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.documentURL
            }, "Online-Datenschutzinformation herunterladen", 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}