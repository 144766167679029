const LOCAL_STORAGE_ITEMS = {
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh',
    EMAIL: 'email',
    //TODO: remove this when the BE token implremented
    NAVIGATED_FROM: 'navigated-from',
}

export default {
    LOCAL_STORAGE_ITEMS,
}
