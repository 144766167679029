<template>
    <div>
        <small id="zuruck" class="mb-5">
            <a href="#" @click="$router.go(-1)">&lt; {{ $t('miscellaneous.back') }}</a>
        </small>
        <h2 class="title mb-3">{{ $t('miscellaneous.terms_and_conditions') }}</h2>
        <div id="tnc-text">
            <div v-html="termsAndConditionsContent"></div>
            <p>
                <a v-if="documentURL" :href="documentURL">{{ $t('miscellaneous.download_tnc') }}</a>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import store from '@/store'
import commonUtils from '@/shared/mixins/utils'

export default defineComponent({
    setup() {
        const documentURL = computed(() => store.state.config.pages.termsdetails.file)

        // text is base64 encoded, first decode it
        const termsAndConditionsContent = computed(() =>
            commonUtils.b64DecodeUnicode(store.state.config.pages.termsdetails.text)
        )

        return {
            termsAndConditionsContent,
            documentURL,
        }
    },
})
</script>

<style lang="scss" scoped></style>
