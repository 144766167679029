<template>
    <div class="data-privacy" v-if="isDataPrivacyEnabled">
        <div class="action-wrapper" v-if="!isInfoVisible" @click="showPrivacyPolicyInfo">
            <div class="data-privacy-button-link">Datenschutz</div>
        </div>

        <div
            v-if="isInfoVisible && privacyPolicyDetails"
            class="info-wrapper"
            ref="privacyPolicyInfoEle"
        >
            <h4>Datenschutz</h4>
            <div class="info">
                <span v-html="privacyPolicyDetails"> </span>
            </div>

            <div class="move-up">
                <i class="fas fa-times" @click="hidePrivacyPolicyInfo()"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import store from '@/store'

export default defineComponent({
    setup() {
        const isDataPrivacyEnabled = ref(false)
        const isInfoVisible = ref(false)
        const privacyPolicyInfoEle = ref()
        const privacyPolicyDetails = ref('')

        const showPrivacyPolicyInfo = () => {
            isInfoVisible.value = true
            setTimeout(() => {
                privacyPolicyInfoEle.value?.scrollIntoView({ behavior: 'smooth' })
            }, 100)
        }

        const hidePrivacyPolicyInfo = () => {
            isInfoVisible.value = false
        }

        const setPrivacyPolicyValues = () => {
            isDataPrivacyEnabled.value = store.state.config.show_data_privacy
            privacyPolicyDetails.value = store.state.config.data_privacy_text
        }

        onMounted(() => {
            setPrivacyPolicyValues()
        })

        return {
            isDataPrivacyEnabled,
            isInfoVisible,
            privacyPolicyInfoEle,
            privacyPolicyDetails,
            showPrivacyPolicyInfo,
            hidePrivacyPolicyInfo,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.data-privacy {
    flex-direction: column;

    .action-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: rem(10px);
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 10px;
        .data-privacy-button-link {
            background: none;
            border: none;
            text-decoration: underline;
            cursor: pointer;
            padding-bottom: 10px;
        }

        .action-icon {
            animation: down 2s ease infinite;
        }

        @keyframes down {
            0% {
                transform: translate(0);
            }

            20% {
                transform: translateY(10px);
            }

            40% {
                transform: translate(0);
            }
        }

        @-webkit-keyframes down {
            0% {
                transform: translate(0);
            }

            20% {
                transform: translateY(10px);
            }

            40% {
                transform: translate(0);
            }
        }
    }

    .info-wrapper {
        margin-top: 1.5em;
        width: 100%;
        border: 1px solid var(--tqs-input-border-color);
        border-radius: 10px;
        padding: 0em 1.5em 1.5em 1.5em;
        .info {
            font-size: rem(14px);
            padding: 5px;
        }

        .move-up {
            margin-top: 10px;
            width: 100%;
            text-align: right;
            i {
                cursor: pointer;
            }
        }
    }
}
</style>
