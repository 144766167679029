<template>
    <div>
        <ToasterView
            :isSuccess="toasterProps.isSuccess"
            :toasterContent="toasterProps.toasterContent"
            :showToaster="toasterProps.showToaster"
        />
        <div class="home-wrapper" :class="menuIsOpen ? 'menu-is-open' : ''" @click="closeMenu">
            <div class="side-bar-wrapper">
                <SideBar v-if="!loading" :isSuperPartner="isSuperPartner" />
            </div>
            <div class="content-wrapper">
                <div class="header-section-wrapper" ref="headerWrapper">
                    <HeaderView
                        :partnerLogo="partnerLogo"
                        :firstLetterOfFirstName="firstLetterOfFirstName"
                        :firstLetterOfLastName="firstLetterOfLastName"
                        :partnerFullName="partnerFullName"
                        :isLoggedInUserSuperPartner="isLoggedInUserSuperPartner"
                    />
                </div>
                <div class="top-bg-wrapper"></div>
                <div
                    :class="[
                        'bottom-wrapper',
                        $route.name === 'QuotaApplications' ? '' : 'other-pages',
                    ]"
                    ref="bottomWrapper"
                >
                    <router-view v-if="!loading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import SideBar from '@/end-user-portal/shared/components/SideBar.vue'
import ToasterView from '@/end-user-portal/shared/components/ToasterView.vue'
import store from '@/store'
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, watch } from 'vue'
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import HeaderView from '@/end-user-portal/shared/components/HeaderView.vue'
import NProgress from 'nprogress'

export default defineComponent({
    components: { SideBar, ToasterView, HeaderView },
    setup() {
        const toasterProps: Ref = ref([])
        const partnerLogo = computed(() => store.state.config.logo)
        const partnerFullName = ref('')
        const firstLetterOfFirstName = ref('')
        const firstLetterOfLastName = ref('')
        const isSuperPartner = ref(true)
        const loading = ref(false)
        const isLoggedInUserSuperPartner = ref(false)
        const menuIsOpen = ref(store.state.menuIsOpened)
        const bottomWrapper: Ref<HTMLElement | null> = ref(null)
        const headerWrapper: Ref<HTMLElement | null> = ref(null)

        // get profile details
        const getProfileDetails = async () => {
            loading.value = true
            await AuthenticationService.getProfileDetails()
                .then((response) => {
                    loading.value = false
                    const profileDetails = response.data
                    router.push({
                        name: 'RegistrationsView',
                    })
                    // get FE config details and set background colors globally

                    store.commit('SET_PROFILE_DETAILS', profileDetails)
                })
                .catch((error) => {
                    loading.value = false
                    NProgress.done()
                    if (error.response.status === 403) {
                        router.push({
                            name: 'SignupView',
                        })
                    }
                    console.error('Error in get profile details')
                })
        }

        // get partner filter values after the component has been mounted
        onMounted(async () => {
            await getProfileDetails()
        })

        const onWindowResize = () => {
            // update window inner width
            if (bottomWrapper.value?.style) {
                bottomWrapper.value.style.height = `calc(100vh - ${headerWrapper.value?.clientHeight}px)`
            }
        }

        onMounted(() => {
            // Listen to window resize event
            window.addEventListener('resize', onWindowResize)
            onWindowResize()
        })

        onUnmounted(() => {
            // Remove window resize event listener
            window.removeEventListener('resize', onWindowResize)
        })

        const closeMenu = () => {
            store.commit('SET_MENU_IS_OPEN', false)
        }

        // update menuIsOpened value when the menu is triggered
        watch(
            () => store.state.menuIsOpened,
            () => {
                menuIsOpen.value = store.state.menuIsOpened
            }
        )

        // watch for toaster value changes and set toaster props
        watch(
            () => store.state.toaster,
            () => {
                if (store.state.toaster) {
                    toasterProps.value = store.state.toaster
                }
            },
            {
                immediate: true,
            }
        )
        return {
            menuIsOpen,
            toasterProps,
            isSuperPartner,
            firstLetterOfFirstName,
            firstLetterOfLastName,
            partnerFullName,
            partnerLogo,
            loading,
            isLoggedInUserSuperPartner,
            headerWrapper,
            bottomWrapper,
            closeMenu,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';

.home-wrapper {
    display: flex;

    .content-wrapper {
        width: 100%;
        min-width: 280px;
        .top-bg-wrapper {
            height: 400px;
            background-image: linear-gradient(90deg, $primary-color-left, $primary-color-right);
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: -1;
            //background-color: $primary-color;
        }

        .bottom-wrapper {
            height: 100vh;
            padding: 0 20px;
            overflow-y: auto;
        }
    }
    @include respond-to('max-small') {
        .content-wrapper {
            .bottom-wrapper {
                padding: 0 15px;
            }
        }
    }
}
</style>
