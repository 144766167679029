import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72aeb302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recaptcha-otp-flow" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "otp-wrapper" }
const _hoisted_5 = { class: "field login-btn mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OtpInput = _resolveComponent("OtpInput")!
  const _component_ButtonPV = _resolveComponent("ButtonPV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-msg-wrapper", [_ctx.isFormMsg ? 'show' : '', _ctx.isError ? 'error' : 'success']])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.formErrorContent), 1)
    ], 2),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('otp.title')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('otp.description')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_OtpInput, {
        class: "otp",
        value: _ctx.otpCode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otpCode) = $event)),
        "input-classes": "otp-input",
        separator: "",
        "num-inputs": 6,
        "should-auto-focus": true,
        "is-input-num": true,
        onOnComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.verifyRecaptchaOTP()))
      }, null, 8, ["value"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ButtonPV, {
          label: _ctx.$t('otp.resend_btn'),
          class: "p-button-raised w-full primary",
          type: "submit",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resendOtp()))
        }, null, 8, ["label"])
      ])
    ])
  ]))
}