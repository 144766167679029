import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TutorialView = _resolveComponent("TutorialView")!
  const _component_UploadView = _resolveComponent("UploadView")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TutorialView, {
          showDot: _ctx.showDot,
          error: _ctx.error,
          errorMessage: _ctx.errorMessage,
          onPhotoAdded: _ctx.photoAdded,
          loading: _ctx.loading,
          onGoBackToValidation: _ctx.goBackToValidationBackside,
          isBevRegCreated: _ctx.isBevRegCreated
        }, null, 8, ["showDot", "error", "errorMessage", "onPhotoAdded", "loading", "onGoBackToValidation", "isBevRegCreated"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_UploadView, {
          showDot: _ctx.showDot,
          error: _ctx.error,
          errorMessage: _ctx.errorMessage,
          onPhotoAdded: _ctx.photoAdded,
          loading: _ctx.loading,
          onGoBackToValidation: _ctx.goBackToValidationBackside,
          isBevRegCreated: _ctx.isBevRegCreated
        }, null, 8, ["showDot", "error", "errorMessage", "onPhotoAdded", "loading", "onGoBackToValidation", "isBevRegCreated"])
      ]))
}