<template>
    <div class="termsdetails view-root">
        <small id="zuruck" class="mb-5">
            <a href="#" @click="router.go(-1)">&lt; Zurück</a>
        </small>
        <div id="termstext">
            <p v-html="text"></p>
            <p>
                <a v-if="documentURL" :href="documentURL"
                    >Allgemeine Geschäftsbedingungen herunterladen</a
                >
            </p>
        </div>

        <div class="action-wrapper mt-5">
            <ButtonPV label="Zurück" class="primary w-full" @click="router.go(-1)" />
        </div>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'
import router from '@/router'
import { isMobile } from 'mobile-device-detect'
import commonUtils from '@/shared/mixins/utils'

export default defineComponent({
    setup() {
        const documentURL = store.state.config.pages.termsdetails.file

        // text is base64 encoded, first decode it
        const text = commonUtils.b64DecodeUnicode(store.state.config.pages.termsdetails.text)
        return {
            showDot: store.state.config.display_title_dot,
            router,
            isMobile,
            documentURL,
            text,
        }
    },
})
</script>

<style scoped lang="scss">
.title-text {
    display: block !important;
}

.p-component {
    font-family: var(--tqs-font-family);
}
</style>
