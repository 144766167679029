<template>
    <div class="toaster" :class="[isSuccess ? 'success' : 'danger', showToaster ? 'show' : '']">
        <p>{{ toasterContent }}</p>
    </div>
</template>

<script lang="ts">
export default {
    props: ['isSuccess', 'toasterContent', 'showToaster'],
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.toaster {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: none;
    z-index: 9999;

    &.success {
        background-color: #71c520;
    }

    &.danger {
        background-color: #c02929;
    }

    &.show {
        display: block;
    }

    p {
        color: $white !important;
        margin: 0;
    }
}
</style>
