<template>
    <div class="code-validation view-root">
        <div>
            <div :class="utils.getTitleClass()">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                Bestätigungs-E-Mail
            </div>

            <p>
                Es wurde soeben eine E-Mail an {{ email }} mit einem Verifizierungslink versendet.
            </p>

            <p>Bitte klicken Sie auf den Bestätigungslink, um die Registrierung fortzusetzen.</p>
            <div>
                <small style="fontstyle: italic">Keine E-Mail erhalten?</small>
            </div>
            <p>
                Überprüfen Sie bitte Ihren Spam-Ordner, oder starten Sie die Registrierung erneut.
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from '@/router'
import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'

export default defineComponent({
    beforeRouteLeave(to, from, next) {
        if (to.name == 'LoginCA') {
            next(false)
        } else {
            next()
        }
    },
    setup() {
        return {
            router,
            utils,
            showDot: store.state.config.display_title_dot,
            email: router.currentRoute.value?.query?.email,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.otp-wrapper {
    #otp {
        justify-content: center;
    }
    .help-text {
        text-align: center;
        font-size: rem(12px);
        font-style: italic;
    }
}
.action-wrapper {
    text-align: center;
}
</style>
