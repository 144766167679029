// import VueRouter, { RouteConfig } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import routerClientApp from '@/client-app/router/index'
import routerPortal from '@/end-user-portal/router/index'

const router = createRouter({
    history: createWebHistory(),
    routes: [...routerClientApp, ...routerPortal],
})

export default router
