import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55737e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify-email view-root" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueLoading, {
      opacity: 0.8,
      active: _ctx.loading,
      "can-cancel": true,
      "is-full-page": true,
      color: "#778da0"
    }, null, 8, ["active"]),
    (_ctx.key)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Überprüfung Ihrer E-Mail-Adresse..."))
      : _createCommentVNode("", true)
  ]))
}