export enum QAStatus {
    CREATED = 'qa_created',
    ENTERED = 'qa_entered',
    PICKED_UP = 'qa_picked_up',
    SENT_TO_UBA = 'qa_sent_to_uba',
    ACCEPTED = 'qa_accepted',
    REJECTED_DOUBLE_REGISTRATION = 'qa_double_registration',
    REJECTED_COLOR_COPY_UNREADABLE = 'qa_color_copy_unreadable',
    REJECTED_NOT_A_PURE_ELECTRIC_CAR = 'qa_not_a_electric_car',
    REJECTED_APPROVAL_DATE_IN_THE_FUTURE = 'qa_approval_date_in_the_future',
    REJECTED_BY_PLATFORM = 'qa_rejected_by_platform',
    TO_PAY = 'qa_to_pay',
    PAID = 'qa_paid',
    PAYMENT_FAILURE = 'qa_payment_failure',
    COMPLETED = 'qa_completed',
    TEST = 'qa_test',
    MANUALLY_REJECTED = 'qa_manually_rejected',
    REJECTED_BY_AUTHORITY = 'qa_rejected_by_authority',
    REJECTED_BY_PARTNER = 'qa_rejected_by_partner',
    TO_SETTLE = 'qa_to_settle',
}

export enum StatusTimelines {
    HISTORY = 'historical',
    CURRENT = 'current',
    FUTURE = 'future',
}
