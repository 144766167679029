import { LocationQueryValue } from 'vue-router'
import AxiosInstance from '@/shared/axios/AxiosInstance'
import variables from '@/end-user-portal/shared/constant/Variables'
import commonVariables from '@/shared/Variables'
import utils from '@/shared/mixins/utils'

const defaultHeaders = {
    headers: {
        'X-CLIENT-DOMAIN': 'end_user_portal',
        'X-SUBDOMAIN': utils.subdomain,
    },
}

export default {
    setAuthentication(data: { username: string; password: string; recaptcha: string }) {
        return AxiosInstance.post(variables.API.loginURL, data, defaultHeaders)
    },
    signUp(data: { password: string }) {
        return AxiosInstance.post(variables.API.signupURL, data, defaultHeaders)
    },
    refreshAccessToken(data: { refresh: string | null }) {
        return AxiosInstance.post(variables.API.getNewAccessTokenURL, data, defaultHeaders)
    },
    getProfileDetails() {
        return AxiosInstance.get(variables.API.getProfileURL, defaultHeaders)
    },
    clearAuthentication(data: { refresh: string | null }) {
        return AxiosInstance.post(variables.API.logoutURL, data, defaultHeaders)
    },
    updateTokens(accessToken: string, refreshToken: string) {
        localStorage.setItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN, accessToken)
        localStorage.setItem(commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, refreshToken)
    },
    resetPasswordVerifyEmail(data: { email: string }) {
        return AxiosInstance.post(variables.API.resetPasswordVerifyEmailURL, data, defaultHeaders)
    },
    resetPasswordVerify(token: string | LocationQueryValue[], id: string | LocationQueryValue[]) {
        return AxiosInstance.get(
            variables.API.resetPasswordVerifyURL + id + '/' + token + '/',
            defaultHeaders
        )
    },
    resetPasswordComplete(data: {
        password: string
        password_again: string
        token: string
        uidb64: string
        recaptcha: string
    }) {
        return AxiosInstance.patch(variables.API.resetPasswordCompleteURL, data, defaultHeaders)
    },
    getFrontendConfigDetails(subdomain: string) {
        return AxiosInstance.get(`${variables.API.getFrontendConfigDetailsURL}/${subdomain}/`)
    },
    verifySignupEmail(data: { key: string }) {
        return AxiosInstance.post(variables.API.verifySignupEmail, data, defaultHeaders)
    },
    sendEmail(data: { email: string; partner: string; verify_email_url: string }) {
        return AxiosInstance.post(variables.API.sendEmail, data, defaultHeaders)
    },
    verifyRecaptchaOTP(data: { user_id: number; otp_code: string }) {
        return AxiosInstance.post(variables.API.verifyRecaptchaOTP, data)
    },
    resendRecaptchaOtpCode(data: { user_id: number }) {
        return AxiosInstance.post(variables.API.resendRecaptchaOtpCode, data, defaultHeaders)
    },
}
