import errors from '@/client-app/assets/errors.json'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'
import store from '@/store'

interface ErrorMessages {
    [key: string]: string
}

const host = store.state.partner
const isEnercity = host.includes('enercity')

const getTitleClass = (): string => {
    return isEnercity ? 'title-text-enercity' : 'title-text'
}

// validate fields and return true if field has errors
const validateMandatoryFields = (field: string | number | [], type: string): boolean => {
    switch (type) {
        case 'string':
            return field === '' ? true : false
        case 'array':
            return Array.isArray(field) && field.length === 0 ? true : false
        case 'number':
            return field === 0 ? true : false
        default:
            return false
    }
}

// Validate per the HTML5 spec:
// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const validateEmail = (email: string): boolean => {
    const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return emailRegex.test(email)
}

// remove spaces in string
const removeSpace = (text: string) => {
    if (!text) return ''
    return text.replace(/\s+/g, '')
}

//Validate per ticket descriptions
const validateTaxID = (taxID: string): boolean => {
    /* these IDs always start with DE and then have 9 of numbers afterward
     * allow spaces and "-" to pass validation(DE-123456789, de 12345789) */
    const regex1 = /^de[ -]?\d{9}$/gim
    //allow 13 digits and the 5th digit is a 0
    const regex2 = /^\d{4}0\d{8}$/
    //allow taxID when the length is 10,11 or 12 with only numeric validation(regex3)
    const regex3 = /^[0-9]+$/

    if (regex1.test(taxID)) {
        return true
    } else if (taxID.length <= 12 && taxID.length >= 10) {
        return regex3.test(taxID)
    } else if (regex2.test(taxID)) {
        return true
    } else {
        return false
    }
}

// Validate the length of any string so it's longer than
// or equal to 'len" amount of characters.
const validateStringLength = (str: string, len: number): boolean => {
    return str.length >= len
}

// Check for emojis in a user input text field
const hasEmojis = (textField: string): boolean => {
    const emojiRegex =
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
    return emojiRegex.test(textField)
}

const hasEmojisOverall = <T extends Record<string, string | { name: string; code: string }>>(
    obj: T
): boolean => {
    let k: keyof T
    for (k in obj) {
        if (hasEmojis(obj[k] as string)) {
            return true
        }
    }
    return false
}

// check for errors in forms
const hasErrors = (obj: ErrorObjectDTO) => {
    const errors = []
    for (const [key] of Object.entries(obj)) {
        for (const [, value] of Object.entries(obj[key])) {
            errors.push(value)
        }
    }
    //  return true if errors contains in any of fields
    return errors.includes(true)
}

const blockNavigationBack = (): void => {
    history.pushState(null, '', location.href)
    window.onpopstate = () => {
        history.go(1)
    }
}

// get error messages mapped to the specific error code
const getErrorMessage = (errorCode: string) => {
    const errorCodes: ErrorMessages = errors
    return errorCodes[errorCode]
}

export default {
    validateMandatoryFields,
    validateEmail,
    hasEmojis,
    hasEmojisOverall,
    hasErrors,
    validateTaxID,
    blockNavigationBack,
    getTitleClass,
    validateStringLength,
    host,
    getErrorMessage,
    removeSpace,
}
