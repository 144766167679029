<template>
    <ImpressumPage></ImpressumPage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ImpressumPage from '@/shared/components/ImpressumPage.vue'

export default defineComponent({
    components: { ImpressumPage },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';
.impressum {
    background-color: $white;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    min-height: calc(100vh - 170px);
    max-height: calc(100vh - 110px);
    overflow: auto;
}
</style>
