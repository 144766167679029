<template>
    <div class="terms view-root">
        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            {{ conf.tnc_title }}
        </div>

        <TermsList ref="termsListRef" />
        <!-- disable button when there are no years enabled from BE -->
        <div class="action-wrapper mt-5">
            <ButtonPV
                label="Weiter"
                class="primary w-full"
                @click="submit()"
                id="terms-button"
                :disabled="displayNoYearAvailableMessage"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, ref, provide } from 'vue'
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'
import commonUtils from '@/shared/mixins/utils'
import variables from '@/client-app/shared/variables'
import store from '@/store'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'
import TermsList from '@/shared/components/TermsList.vue'

export default defineComponent({
    components: {
        TermsList,
    },
    setup() {
        const displayYearChoicesMandatoryError = ref(false)
        const termsListRef: Ref = ref(null)

        const formModel = ref({
            ADDITIONAL_REQUIREMENT: false,
            // mark terms check true by default only for Eon
            TERMS: commonUtils.useComputations().isEONPartner,
            PRIVACY: commonUtils.useComputations().isEONPartner,
            CURRENT_YEAR: false,
            NEXT_YEAR: false,
            EMAIL_PROMOTION: false,
        })

        const errors: Ref<ErrorObjectDTO> = ref({
            CURRENT_YEAR: { mandatory: false },
            NEXT_YEAR: { mandatory: false },
            ADDITIONAL_REQUIREMENT: { mandatory: false },
            TERMS: { mandatory: false },
            PRIVACY: { mandatory: false },
        })
        provide('errors', errors)
        provide('displayYearChoicesMandatoryError', displayYearChoicesMandatoryError)
        provide('formModel', formModel)

        const submit = async () => {
            if (termsListRef.value) {
                termsListRef.value.validateForm()
            }
            // check if the form has errors
            if (utils.hasErrors(errors.value) || displayYearChoicesMandatoryError.value) {
                return
            }

            // store terms and privacy values to send after code validation
            const tcAndPp = {
                additionalRequirement: formModel.value.ADDITIONAL_REQUIREMENT,
                terms: formModel.value.TERMS,
                privacy: formModel.value.PRIVACY,
                currentYear: formModel.value.CURRENT_YEAR,
                nextYear: formModel.value.NEXT_YEAR,
                emailPromotion: formModel.value.EMAIL_PROMOTION,
            }

            // store free_text field check value
            localStorage.setItem(
                variables.LOCAL_STORAGE_ITEMS.FREE_TEXT_CHECKED,
                JSON.stringify(tcAndPp.additionalRequirement)
            )
            // commit terms and privacy values to send after code validation
            store.commit('setTcAndPP', tcAndPp)
            // store terms and privacy checked as true to use in router guard
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.TC_AND_PP_CHECKED, 'true')
            router.push({ name: 'LoginCA', query: router.currentRoute.value.query })
        }
        return {
            conf: store.state.config.pages?.terms,
            showDot: store.state.config.display_title_dot,
            utils,
            submit,
            displayNoYearAvailableMessage:
                store.state.config.qa_create_choices?.no_allowed_registration_years_message,
            termsListRef,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.action-wrapper {
    text-align: center;
}
</style>
