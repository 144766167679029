<template>
    <div class="checkboxes tnc">
        <!-- display message from BE when no year has `true`(enabled) -->
        <MessagePV severity="info" :closable="false" v-if="displayNoYearAvailableMessage">
            {{ displayNoYearAvailableMessage }}
        </MessagePV>
        <!-- display if it's `true` from BE -->
        <div v-if="(currentYearChoiceRule && !isEup) || (isEup && disableYears.CURRENT_YEAR)">
            <div class="field-checkbox">
                <CheckboxPV
                    id="terms"
                    v-model="formModel.CURRENT_YEAR"
                    :binary="true"
                    :disabled="disableYears.CURRENT_YEAR"
                    @change="$emit('terms-click-handler', formModel)"
                />
                <div v-html="currentYearCheckboxTitleText"></div>
            </div>
            <div class="sub-text" v-html="currentYearTitles.subtitle"></div>
            <small class="p-error" v-if="errors.CURRENT_YEAR.mandatory">
                Bitte akzeptieren Sie den Zeitraum um fortzufahren.
            </small>
        </div>
        <!-- display if it's `true` from BE -->
        <div v-if="(nextyearChoiceRule && !isEup) || (isEup && disableYears.NEXT_YEAR)">
            <div class="field-checkbox">
                <!-- display if it's mandatory/optional -->
                <CheckboxPV
                    id="terms"
                    v-model="formModel.NEXT_YEAR"
                    :binary="true"
                    :disabled="disableYears.NEXT_YEAR"
                    @change="$emit('terms-click-handler', formModel)"
                />
                <div v-html="nextYearCheckboxTitleText"></div>
            </div>
            <div class="sub-text" v-html="nextYearCheckboxSubText"></div>
            <small class="p-error" v-if="errors.NEXT_YEAR.mandatory">
                Bitte akzeptieren Sie den Zeitraum um fortzufahren.
            </small>
        </div>
        <div>
            <small class="p-error" v-if="displayYearChoicesMandatoryError">
                Bitte wählen Sie mindestens ein Quotenjahr aus, um fortzufahren
            </small>
        </div>
        <div class="field-checkbox">
            <CheckboxPV
                id="terms"
                v-model="formModel.TERMS"
                :binary="true"
                @change="$emit('terms-click-handler', formModel)"
            />
            <div>
                Ich habe die
                <a
                    @click="
                        () => {
                            router.push({
                                name: 'TermsDetails',
                                query: router.currentRoute.value.query,
                            })
                            $emit('terms-click-handler', formModel)
                        }
                    "
                >
                    Allgemeinen Geschäftsbedingungen (AGB)
                </a>
                <span v-if="thg_quoten_is_contractual_partner">
                    {{ by_line }}
                </span>
                gelesen und bin damit einverstanden.
            </div>
        </div>
        <small class="p-error" v-if="errors.TERMS.mandatory">
            Akzeptieren der AGB ist erforderlich.
        </small>
        <div class="field-checkbox">
            <CheckboxPV
                id="privacy"
                v-model="formModel.PRIVACY"
                :binary="true"
                value=""
                @change="$emit('terms-click-handler', formModel)"
            />
            <div>
                Ich habe die
                <a
                    @click="
                        () => {
                            router.push({
                                name: 'PrivacyDetails',
                                query: router.currentRoute.value.query,
                            })
                            $emit('privacy-click-handler', formModel)
                        }
                    "
                >
                    Datenschutzinformation
                </a>
                <span v-if="thg_quoten_is_contractual_partner">
                    {{ by_line }}
                </span>
                zur Kenntnis genommen.
            </div>
        </div>
        <small class="p-error" v-if="errors.PRIVACY.mandatory">
            Akzeptieren der Datenschutzbestimmungen ist erforderlich.
        </small>
        <div v-if="conf.display_checkbox_free_text_permission" class="field-checkbox">
            <CheckboxPV
                id="additional"
                v-model="formModel.ADDITIONAL_REQUIREMENT"
                :binary="true"
                @change="$emit('terms-click-handler', formModel)"
            />
            <div>
                {{
                    conf.free_text_permission_text &&
                    commonUtils.b64DecodeUnicode(conf.free_text_permission_text)
                }}
            </div>
        </div>
        <small class="p-error" v-if="errors.ADDITIONAL_REQUIREMENT.mandatory">
            Akzeptieren ist erforderlich.
        </small>
        <div v-if="conf.show_email_promotions_flag">
            <div class="field-checkbox">
                <CheckboxPV
                    id="additional"
                    v-model="formModel.EMAIL_PROMOTION"
                    :binary="true"
                    @change="$emit('terms-click-handler', formModel)"
                />
                <div v-html="conf.email_promotions_text"></div>
            </div>
            <div class="collapsible" v-if="conf.fly_out_text">
                <button @click="toggleCollapse">
                    <span class="arrow" :class="{ up: isCollapsed }"></span>
                </button>
                <div class="content" :class="{ collapsed: isCollapsed }">
                    <div v-html="conf.fly_out_text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, inject, watch } from 'vue'
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'
import store from '@/store'
import commonUtils from '@/shared/mixins/utils'

export default defineComponent({
    props: {
        selectedYear: {},
        isEup: Boolean,
    },
    setup(props) {
        const currentYearChoiceRule = store.state.config.qa_create_choices?.current_year
        const nextyearChoiceRule = store.state.config.qa_create_choices?.next_year
        // set default titles to FE config values
        const currentYearTitles = ref({
            title: store.state.config.qa_create_choices?.current_year_option_title,
            subtitle: store.state.config.qa_create_choices?.current_year_option_subtext,
        })
        const disableYears = ref({
            CURRENT_YEAR: false,
            NEXT_YEAR: false,
        })

        const formModel = inject(
            'formModel',
            ref({
                ADDITIONAL_REQUIREMENT: false,
                TERMS: false,
                PRIVACY: false,
                CURRENT_YEAR: false,
                NEXT_YEAR: false,
                EMAIL_PROMOTION: false,
            })
        )

        const errors = inject(
            'errors',
            ref({
                CURRENT_YEAR: { mandatory: false },
                NEXT_YEAR: { mandatory: false },
                ADDITIONAL_REQUIREMENT: { mandatory: false },
                TERMS: { mandatory: false },
                PRIVACY: { mandatory: false },
            })
        )
        const isCollapsed = ref(false)
        const displayYearChoicesMandatoryError = inject(
            'displayYearChoicesMandatoryError',
            ref(false)
        )

        // display select at least one year error if user hasn't selected any year choices when they are `OPTIONAL` (#209)
        const checkYearSelection = () => {
            const yearValues = []
            // store year choices values in a array when they are `true` from BE
            if (currentYearChoiceRule) {
                yearValues.push(formModel.value.CURRENT_YEAR)
            }
            if (nextyearChoiceRule) {
                yearValues.push(formModel.value.NEXT_YEAR)
            }
            // check if user has selected any years (check for `true` values)
            const findSelectedYear = yearValues.filter((choice) => choice === true).length
            // if there are no `true` value found, display the error
            if (findSelectedYear === 0) {
                displayYearChoicesMandatoryError.value = true
            } else {
                displayYearChoicesMandatoryError.value = false
            }
        }

        const validateForm = () => {
            displayYearChoicesMandatoryError.value = false
            if (
                store.state.config.pages.terms.display_checkbox_free_text_permission &&
                store.state.config.pages.terms.make_free_text_mandatory
            ) {
                errors.value.ADDITIONAL_REQUIREMENT.mandatory =
                    !formModel.value.ADDITIONAL_REQUIREMENT
            }

            // display error message when user hasn't selected any year choices, although they are enabled from BE
            if ([currentYearChoiceRule, nextyearChoiceRule].includes(true)) {
                checkYearSelection()
            }

            errors.value.TERMS.mandatory = !formModel.value.TERMS
            errors.value.PRIVACY.mandatory = !formModel.value.PRIVACY
        }

        // current year checkbox title label
        const currentYearCheckboxTitleText = computed(() => {
            return `${currentYearTitles.value.title ?? ''} (${new Date().getFullYear()})`
        })

        // next year checkbox title label
        const nextYearCheckboxTitleText = computed(() => {
            return `${store.state.config.qa_create_choices?.next_year_option_title ?? ''} (${
                new Date().getFullYear() + 1
            })`
        })

        const toggleCollapse = () => {
            isCollapsed.value = !isCollapsed.value
        }

        const setDefaultValues = (year: number) => {
            disableYears.value = {
                CURRENT_YEAR: false,
                NEXT_YEAR: false,
            }
            // if user has selects current year, check current year by default and disable it
            if (year === new Date().getFullYear() && currentYearChoiceRule) {
                formModel.value.CURRENT_YEAR = true
                disableYears.value.CURRENT_YEAR = true
                // if user has selects next year, check next year by default and disable it
            } else if (year === new Date().getFullYear() + 1 && nextyearChoiceRule) {
                formModel.value.NEXT_YEAR = true
                disableYears.value.NEXT_YEAR = true
            }
        }

        watch(
            () => props.selectedYear,
            () => {
                if (props.selectedYear) {
                    setDefaultValues(Number(props.selectedYear))
                }
            },
            {
                immediate: true,
            }
        )

        return {
            conf: store.state.config.pages?.terms,
            showDot: store.state.config.display_title_dot,
            by_thg_quoten_gmbh: store.state.config.by_thg_quoten_gmbh,
            thg_quoten_is_contractual_partner: store.state.config.thg_quoten_is_contractual_partner,
            by_line: store.state.config.pages?.welcome.by_line,
            partnerName: store.state.partner,
            utils,
            router,
            errors,
            currentYearCheckboxTitleText,
            currentYearTitles,
            nextYearCheckboxTitleText,
            nextYearCheckboxSubText: store.state.config.qa_create_choices?.next_year_option_subtext,
            displayYearChoicesMandatoryError,
            currentYearChoiceRule,
            nextyearChoiceRule,
            commonUtils,
            toggleCollapse,
            isCollapsed,
            displayNoYearAvailableMessage:
                store.state.config.qa_create_choices?.no_allowed_registration_years_message,
            validateForm,
            formModel,
            disableYears,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.checkboxes {
    text-align: left;
}

.action-wrapper {
    text-align: center;
}

.field-checkbox {
    margin-top: rem(14px);
    margin-bottom: 0px;
}

.p-component {
    font-family: var(--tqs-font-family);
}
.sub-text {
    margin-left: rem(28px);
}
.collapsible {
    margin-left: 1.5rem;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

.arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid var(--tqs-frontend-text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: transform 0.5s ease;
}

.arrow.up {
    transform: rotate(-135deg);
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.content.collapsed {
    max-height: 500px; /* Adjust the value as needed */
}
#terms,
#privacy,
#additional {
    margin-right: 0.5rem;
}
</style>
