<template>
    <div class="verify-email view-root">
        <!-- loading spinner -->
        <VueLoading
            :opacity="0.8"
            :active="loading"
            :can-cancel="true"
            :is-full-page="true"
            color="#778da0"
        />
        <div v-if="key">Überprüfung Ihrer E-Mail-Adresse...</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import router from '@/router'

import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'
import variables from '@/client-app/shared/variables'
import commonVariables from '@/shared/Variables'
import APIService from '@/client-app/shared/APIService'

export default defineComponent({
    setup() {
        const token = ref('')
        const refreshToken = ref('')
        const error = ref(false)
        const loading = ref(false)
        const errorMessage = ref('')
        const key = router.currentRoute.value.query.key
        // store key values from url in localstorage if incase user navigates manually to forward routes
        const campaign = router.currentRoute.value.query.campaign
        localStorage.setItem(
            variables.LOCAL_STORAGE_ITEMS.CAMPAIGN,
            campaign ? JSON.stringify(campaign) : ''
        )
        const freeTextChecked = router.currentRoute.value.query.freeText
        localStorage.setItem(
            variables.LOCAL_STORAGE_ITEMS.FREE_TEXT_CHECKED,
            JSON.stringify(freeTextChecked)
        )

        // read URL variable value value from url and store in local storage
        const URLVariableValue = router.currentRoute.value.fullPath
        URLVariableValue.includes('campaign')
            ? localStorage.setItem(
                  variables.LOCAL_STORAGE_ITEMS.URL_VARIABLE_VALUE,
                  JSON.stringify(URLVariableValue)
              )
            : localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.URL_VARIABLE_VALUE, '')
        // get authentication key
        const getAuthToken = async () => {
            const data = {
                key: key,
            }
            loading.value = true
            APIService.verifyEmail(data)
                .then((response) => {
                    loading.value = false
                    token.value = response.data.access_token
                    refreshToken.value = response.data.refresh_token
                    if (token.value && refreshToken.value) {
                        continueFlow()
                    }
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Error while receiving token'
                    if (router.currentRoute.value.fullPath.includes('/b2b')) {
                        router.push({ path: '/b2b' })
                    } else {
                        router.push({ name: 'Welcome' })
                    }
                })
        }

        onMounted(() => {
            getAuthToken()
        })

        // When user clicks the button to continue on desktop
        const continueFlow = () => {
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.AUTHENTICATED, 'true')
            localStorage.setItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN, token.value)
            localStorage.setItem(
                commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN,
                refreshToken.value
            )

            const b2b_suffix = router.currentRoute.value.fullPath.includes('/b2b') ? 'B2B' : ''
            router.push({
                name: 'ImageUploadWrapper' + b2b_suffix,
                query: router.currentRoute.value?.query,
            })
        }

        return {
            router,
            utils,
            showDot: store.state.config.display_title_dot,
            loading,
            key,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.otp-wrapper {
    #otp {
        justify-content: center;
    }
    .help-text {
        text-align: center;
        font-size: rem(12px);
        font-style: italic;
    }
}
.action-wrapper {
    text-align: center;
}
</style>
