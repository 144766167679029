<template>
    <div id="upload-wrapper">
        <div>
            <div class="title-text">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                Fahrzeugschein hochladen
            </div>
            <div v-if="error" class="p-error mt-3 mb-3">
                {{ errorMessage }}
            </div>
            <div class="carousel-wrapper">
                <div class="slider-vdo">
                    <transition name="fade" mode="out-in">
                        <template v-if="displayUnfoldedImage">
                            <img
                                class="unfolded-image"
                                src="@/client-app/assets/unfolded-image.png"
                                alt=""
                            />
                        </template>
                        <template v-else>
                            <video class="video" playsInline muted loop autoplay>
                                <source src="@/client-app/assets/vid1.mp4" type="video/mp4" />
                            </video>
                        </template>
                    </transition>

                    <br />
                    <div class="carousel-sub-title">
                        Entfalten Sie Ihren Fahrzeugschein wie in der Animation gezeigt und machen
                        Sie ein Foto.
                    </div>
                    <br />
                    <div class="carousel-sub-title">
                        Laden Sie eine Aufnahme von Ihrem Desktop-Computer hoch.
                    </div>
                </div>
            </div>
            <div>
                <div class="action-wrapper mt-5">
                    <label class="inputlabel" ref="labelEl">
                        <span><i class="far fa-image mr-2"></i>Hochladen</span>
                        <input
                            id="fileinput"
                            @change="photoAdded"
                            type="file"
                            accept="image/*, application/pdf"
                        />
                    </label>
                </div>
                <br />
            </div>
        </div>
        <!-- cancel multiple zlb upload -->
        <div class="action-wrapper mt-1" v-if="isBevRegCreated">
            <ButtonPV
                class="secondary w-full"
                @click="goBackToValidationBackside"
                label="Abbrechen und Registrierung fortführen"
            />
        </div>
        <!-- loading spinner -->
        <VueLoading :opacity="0.8" :active="loading" :can-cancel="true" color="#778da0">
            <div class="loading-text">Wird bearbeitet...</div>
        </VueLoading>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import router from '@/router'
import variables from '@/client-app/shared/variables'
import utils from '@/client-app/shared/mixins/utils'

export default defineComponent({
    props: {
        showDot: Boolean,
        error: Boolean,
        errorMessage: String,
        loading: Boolean,
        isBevRegCreated: Boolean,
    },
    setup(props, { emit }) {
        const displayUnfoldedImage = ref(true)
        // store full path of the routed url to use later when user wants to add more zlb images
        localStorage.setItem(
            variables.LOCAL_STORAGE_ITEMS.ROUTERPATH,
            router.currentRoute.value.fullPath
        )

        const photoAdded = (evt: Event) => {
            emit('photoAdded', evt)
        }

        // go back to backside validation page when user cancel
        const goBackToValidationBackside = () => {
            emit('go-back-to-validation')
        }

        onMounted(() => {
            // switch to display video after 2 seconds of image display
            {
                setTimeout(() => {
                    displayUnfoldedImage.value = false
                }, 2000) // 2 seconds in milliseconds
            }
        })

        return {
            utils,
            router,
            photoAdded,
            goBackToValidationBackside,
            displayUnfoldedImage,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.carousel-wrapper {
    margin-top: rem(32px);
    margin-bottom: rem(64px);
    .slider-vdo {
        display: flex;
        width: 100%;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        .video {
            height: 400px;
        }
        .carousel-title {
            font-size: rem(20px);
            text-align: center;
            margin-bottom: 5px;
            margin-top: 10px;
        }
        .carousel-sub-title {
            font-size: rem(14px);
            text-align: center;
        }
    }
}
#captureinput {
    display: none;
}

#fileinput {
    display: none;
}

.processing {
    font-size: rem(56px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.after-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: -100%;
    width: 200px;
}

.position-absolute {
    position: fixed !important;
}

.p-component {
    font-family: var(--tqs-font-family);
}
.unfolded-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 63.625%;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
