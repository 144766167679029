import { isMobile } from 'mobile-device-detect'
import WelcomeMobile from '@/client-app/views/welcome/WelcomeMobile.vue'
import LoginCA from '@/client-app/views/LoginCA.vue'
import ConfirmView from '@/client-app/views/ConfirmView.vue'
import CodeValidation from '@/client-app/views/CodeValidation.vue'
import TermsView from '@/client-app/views/TermsView.vue'
import FAQ from '@/client-app/views/FAQ.vue'
import TermsDetails from '@/shared/components/TermsDetails.vue'
import PrivacyDetails from '@/shared/components/PrivacyDetails.vue'
import ValidationView from '@/client-app/views/ValidationView.vue'
import BillingView from '@/client-app/views/BillingView.vue'
import FinishView from '@/client-app/views/FinishView.vue'
import VerifyEmail from '@/client-app/views/VerifyEmail.vue'
import WelcomeDesktop from '@/client-app/views/welcome/WelcomeDesktop.vue'
import DesktopView from '@/client-app/views/DesktopView.vue'
import variables from '@/client-app/shared/variables'
import WelcomeB2B from '@/client-app/views/welcome//WelcomeB2B.vue'
import VerifiedView from '@/client-app/views/VerifiedView.vue'
import ImageUploadWrapper from '@/client-app/views/image-upload/ImageUploadWrapper.vue'
import ImpressumPage from '@/shared/components/ImpressumPage.vue'
import FrontendDeactivated from '@/client-app/components/FrontendDeactivated.vue'
import { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import store from '@/store'
/* eslint-disable */

// auth guard
const commonBeforeRules = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (
        ![
            'Welcome',
            'WelcomeB2B',
            'FAQ',
            'Privacy',
            'TermsFull',
            'TermsView',
            'TermsDetails',
            'PrivacyDetails',
            'VerifyEmail',
            'LoginCA',
            'ConfirmView',
            'ConfirmB2B',
            'CodeValidation',
            'CodeValidationB2B',
            'WelcomeDesktop',
            'WelcomeMobile',
            'DesktopView',
            'Impressum',
            'FrontendDeactivated',
            'LoginB2B',
            'VerifyEmailB2B',
        ].includes(to?.name as string) &&
        // No need to visit the 'terms' page when in the b2b flow.
        localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.AUTHENTICATED) !== 'true'
    ) {
        if (!to?.path.includes('/b2b')) {
            next({ name: 'TermsView' })
        } else {
            next({ name: 'LoginB2B' })
        }
    } else {
        next()
    }
}
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/client-app/views/ClientAppWrapper.vue'),
        children: [
            {
                path: '',
                component: () => import('@/client-app/views/welcome/WelcomeWrapper.vue'),
                children: [
                    {
                        path: '',
                        name: 'Welcome',
                        component: isMobile ? WelcomeMobile : DesktopView,
                    },
                    {
                        path: 'b2b/welcome',
                        name: 'WelcomeB2B',
                        component: WelcomeB2B,
                    },
                    {
                        path: 'welcome',
                        name: 'WelcomeDesktop',
                        component: WelcomeDesktop,
                    },
                ],
                alias: '/b2b',
            },
            {
                path: 'login',
                name: 'LoginCA',
                component: LoginCA,
                // if user hasn't checked terms and condition navigate to TermsView
                beforeEnter: (
                    _to: RouteLocationNormalized,
                    _from: RouteLocationNormalized,
                    next: NavigationGuardNext
                ) => {
                    if (
                        localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.TC_AND_PP_CHECKED) !==
                        'true'
                    ) {
                        next({ name: 'TermsView' })
                    } else {
                        next()
                    }
                },
            },
            {
                path: '/confirm',
                name: 'Confirm',
                component: ConfirmView,
                // if user hasn't checked terms and condition navigate to TermsView
                beforeEnter: (
                    _to: RouteLocationNormalized,
                    _from: RouteLocationNormalized,
                    next: NavigationGuardNext
                ) => {
                    if (
                        localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.TC_AND_PP_CHECKED) !==
                        'true'
                    ) {
                        next({ name: 'TermsView' })
                    } else {
                        next()
                    }
                },
            },
            {
                path: '/upload',
                name: 'ImageUploadWrapper',
                component: ImageUploadWrapper,
            },
            {
                path: '/codevalidation',
                name: 'CodeValidation',
                component: CodeValidation,
            },
            {
                path: 'terms',
                name: 'TermsView',
                component: TermsView,
            },
            {
                path: '/termsdetails',
                name: 'TermsDetails',
                component: TermsDetails,
            },
            {
                path: '/privacydetails',
                name: 'PrivacyDetails',
                component: PrivacyDetails,
            },
            { path: '/verify-email', name: 'VerifyEmail', component: VerifyEmail },
            {
                path: '/validation:bevRegistrationId?',
                name: 'Validation',
                component: ValidationView,
                props: true,
            },
            {
                path: '/b2b/validation:bevRegistrationId?',
                name: 'ValidationB2B',
                component: ValidationView,
                props: true,
            },
            {
                path: '/billing',
                name: 'Billing',
                component: BillingView,
            },
            {
                path: '/finish',
                name: 'Finish',
                component: FinishView,
            },
            {
                path: '/faq',
                name: 'FAQ',
                component: FAQ,
            },

            // The following pages are shown in desktop mode
            {
                path: '/terms-full',
                name: 'TermsFull',
                component: TermsDetails,
            },
            {
                path: '/privacy',
                name: 'Privacy',
                component: PrivacyDetails,
                beforeEnter: (to, from, next) => {
                    // redirect to root if show_data_privacy config is enabled and privacydetails text is not empty
                    if (
                        store.state.config.show_data_privacy &&
                        store.state.config.pages.privacydetails.text
                    ) {
                        next()
                    } else {
                        next('/')
                    }
                },
            },
            {
                path: '/b2b/codevalidation',
                name: 'CodeValidationB2B',
                component: CodeValidation,
            },
            {
                path: '/b2b/login',
                name: 'LoginB2B',
                component: LoginCA,
            },
            {
                path: '/b2b/confirm',
                name: 'ConfirmB2B',
                component: ConfirmView,
            },
            {
                path: '/b2b/upload',
                name: 'ImageUploadWrapperB2B',
                component: ImageUploadWrapper,
            },
            {
                path: '/b2b/finish',
                name: 'FinishB2B',
                component: FinishView,
            },
            {
                path: '/b2b/verify-email',
                name: 'VerifyEmailB2B',
                component: VerifyEmail,
            },
            {
                path: '/verified',
                name: 'Verified',
                component: VerifiedView,
                alias: '/b2b/verified',
            },
            {
                path: '/impressum',
                name: 'Impressum',
                component: ImpressumPage,
                alias: '/b2b/impressum',
                beforeEnter: (to, from, next) => {
                    // redirect to root if show_impressum config is enabled and impressum_details text is not empty
                    if (store.state.config.show_impressum && store.state.config.impressum_details) {
                        next()
                    } else {
                        next('/')
                    }
                },
            },
            {
                path: '/deactivated',
                name: 'FrontendDeactivated',
                component: FrontendDeactivated,
            },
        ],
        beforeEnter: (
            to: RouteLocationNormalized,
            _from: RouteLocationNormalized,
            next: NavigationGuardNext
        ) => commonBeforeRules(to, next),
    },
]

export default routes
