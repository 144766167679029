import apiClient from '@/api_client'
import variables from '@/client-app/shared/variables'
import store from '@/store'

const subdomain = store.state.partner
const isEnercity = subdomain.includes('enercity')
// import config from '@/client-app/assets/config.json'
// Load Partner specific configuration
const loadConfiguration = async () => {
    // Config is loaded based on the hostname
    const response = await apiClient.get(`/configs/${subdomain}/`)
    const config = response.data
    store.commit('setConfig', config)
    addGTM(config.gtm_id)
    // Enercity?
    store.commit('setEnercity', isEnercity)
}

const addGTM = (id: string) => {
    if (!id || id == null) {
        return
    }
    if (!id.startsWith('GTM') || id === '' || id.length < 10) {
        return
    }

    const GTMContainerHead: HTMLElement | Element | null = document.querySelector('#head-gtm')
    if (GTMContainerHead) {
        GTMContainerHead.innerHTML =
            "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
            id +
            "');"
    }

    const GTMContainerBody: HTMLElement | Element | null = document.querySelector('#body-gtm')
    if (GTMContainerBody) {
        const GTM_URL = 'https://www.googletagmanager.com/ns.html?id=' + id
        const GTMBodyContent = document.createElement('iframe')
        GTMBodyContent.src = GTM_URL
        GTMBodyContent.height = '0'
        GTMBodyContent.width = '0'
        GTMBodyContent.setAttribute('style', 'display:none;visibility:hidden')
        GTMContainerBody.textContent = ''
        GTMContainerBody.appendChild(GTMBodyContent)
    }
}

// sleep method returns a promise that resolves after some time
const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

const b64DecodeUnicode = (input: string) => {
    return decodeURIComponent(
        atob(input)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )
}

// check for EON wall box partners
const isEONWallbox = [
    variables.WALLBOX_PARTNERS.EON_WALLBOX,
    variables.WALLBOX_PARTNERS.EON_WALLBOXBK,
].includes(subdomain)

const useComputations = () => {
    // Regular expression to match "eon" as a standalone word (for all eon partners)
    const regex = /\beon\b/i
    const isEONPartner: boolean = regex.test(subdomain)
    return { isEONPartner }
}

export default {
    loadConfiguration,
    sleep,
    subdomain,
    b64DecodeUnicode,
    isEONWallbox,
    useComputations,
}
