import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05219758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkboxes tnc" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "field-checkbox" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "field-checkbox" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}
const _hoisted_13 = { class: "field-checkbox" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 3,
  class: "p-error"
}
const _hoisted_16 = { class: "field-checkbox" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 4,
  class: "p-error"
}
const _hoisted_19 = {
  key: 5,
  class: "field-checkbox"
}
const _hoisted_20 = {
  key: 6,
  class: "p-error"
}
const _hoisted_21 = { key: 7 }
const _hoisted_22 = { class: "field-checkbox" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 0,
  class: "collapsible"
}
const _hoisted_25 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessagePV = _resolveComponent("MessagePV")!
  const _component_CheckboxPV = _resolveComponent("CheckboxPV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayNoYearAvailableMessage)
      ? (_openBlock(), _createBlock(_component_MessagePV, {
          key: 0,
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.displayNoYearAvailableMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ((_ctx.currentYearChoiceRule && !_ctx.isEup) || (_ctx.isEup && _ctx.disableYears.CURRENT_YEAR))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CheckboxPV, {
              id: "terms",
              modelValue: _ctx.formModel.CURRENT_YEAR,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.CURRENT_YEAR) = $event)),
              binary: true,
              disabled: _ctx.disableYears.CURRENT_YEAR,
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
            }, null, 8, ["modelValue", "disabled"]),
            _createElementVNode("div", { innerHTML: _ctx.currentYearCheckboxTitleText }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", {
            class: "sub-text",
            innerHTML: _ctx.currentYearTitles.subtitle
          }, null, 8, _hoisted_5),
          (_ctx.errors.CURRENT_YEAR.mandatory)
            ? (_openBlock(), _createElementBlock("small", _hoisted_6, " Bitte akzeptieren Sie den Zeitraum um fortzufahren. "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    ((_ctx.nextyearChoiceRule && !_ctx.isEup) || (_ctx.isEup && _ctx.disableYears.NEXT_YEAR))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_CheckboxPV, {
              id: "terms",
              modelValue: _ctx.formModel.NEXT_YEAR,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.NEXT_YEAR) = $event)),
              binary: true,
              disabled: _ctx.disableYears.NEXT_YEAR,
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
            }, null, 8, ["modelValue", "disabled"]),
            _createElementVNode("div", { innerHTML: _ctx.nextYearCheckboxTitleText }, null, 8, _hoisted_9)
          ]),
          _createElementVNode("div", {
            class: "sub-text",
            innerHTML: _ctx.nextYearCheckboxSubText
          }, null, 8, _hoisted_10),
          (_ctx.errors.NEXT_YEAR.mandatory)
            ? (_openBlock(), _createElementBlock("small", _hoisted_11, " Bitte akzeptieren Sie den Zeitraum um fortzufahren. "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.displayYearChoicesMandatoryError)
        ? (_openBlock(), _createElementBlock("small", _hoisted_12, " Bitte wählen Sie mindestens ein Quotenjahr aus, um fortzufahren "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_CheckboxPV, {
        id: "terms",
        modelValue: _ctx.formModel.TERMS,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.TERMS) = $event)),
        binary: true,
        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", null, [
        _createTextVNode(" Ich habe die "),
        _createElementVNode("a", {
          onClick: _cache[6] || (_cache[6] = 
                        () => {
                            _ctx.router.push({
                                name: 'TermsDetails',
                                query: _ctx.router.currentRoute.value.query,
                            })
                            _ctx.$emit('terms-click-handler', _ctx.formModel)
                        }
                    )
        }, " Allgemeinen Geschäftsbedingungen (AGB) "),
        (_ctx.thg_quoten_is_contractual_partner)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.by_line), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" gelesen und bin damit einverstanden. ")
      ])
    ]),
    (_ctx.errors.TERMS.mandatory)
      ? (_openBlock(), _createElementBlock("small", _hoisted_15, " Akzeptieren der AGB ist erforderlich. "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_CheckboxPV, {
        id: "privacy",
        modelValue: _ctx.formModel.PRIVACY,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.PRIVACY) = $event)),
        binary: true,
        value: "",
        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", null, [
        _createTextVNode(" Ich habe die "),
        _createElementVNode("a", {
          onClick: _cache[9] || (_cache[9] = 
                        () => {
                            _ctx.router.push({
                                name: 'PrivacyDetails',
                                query: _ctx.router.currentRoute.value.query,
                            })
                            _ctx.$emit('privacy-click-handler', _ctx.formModel)
                        }
                    )
        }, " Datenschutzinformation "),
        (_ctx.thg_quoten_is_contractual_partner)
          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.by_line), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" zur Kenntnis genommen. ")
      ])
    ]),
    (_ctx.errors.PRIVACY.mandatory)
      ? (_openBlock(), _createElementBlock("small", _hoisted_18, " Akzeptieren der Datenschutzbestimmungen ist erforderlich. "))
      : _createCommentVNode("", true),
    (_ctx.conf.display_checkbox_free_text_permission)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_CheckboxPV, {
            id: "additional",
            modelValue: _ctx.formModel.ADDITIONAL_REQUIREMENT,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formModel.ADDITIONAL_REQUIREMENT) = $event)),
            binary: true,
            onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.conf.free_text_permission_text &&
                    _ctx.commonUtils.b64DecodeUnicode(_ctx.conf.free_text_permission_text)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.errors.ADDITIONAL_REQUIREMENT.mandatory)
      ? (_openBlock(), _createElementBlock("small", _hoisted_20, " Akzeptieren ist erforderlich. "))
      : _createCommentVNode("", true),
    (_ctx.conf.show_email_promotions_flag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_CheckboxPV, {
              id: "additional",
              modelValue: _ctx.formModel.EMAIL_PROMOTION,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formModel.EMAIL_PROMOTION) = $event)),
              binary: true,
              onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('terms-click-handler', _ctx.formModel)))
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", {
              innerHTML: _ctx.conf.email_promotions_text
            }, null, 8, _hoisted_23)
          ]),
          (_ctx.conf.fly_out_text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("button", {
                  onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["arrow", { up: _ctx.isCollapsed }])
                  }, null, 2)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["content", { collapsed: _ctx.isCollapsed }])
                }, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.conf.fly_out_text
                  }, null, 8, _hoisted_25)
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}