<template>
    <div v-if="isMobile">
        <TutorialView
            :showDot="showDot"
            :error="error"
            :errorMessage="errorMessage"
            @photoAdded="photoAdded"
            :loading="loading"
            @go-back-to-validation="goBackToValidationBackside"
            :isBevRegCreated="isBevRegCreated"
        />
    </div>
    <div v-else>
        <UploadView
            :showDot="showDot"
            :error="error"
            :errorMessage="errorMessage"
            @photoAdded="photoAdded"
            :loading="loading"
            @go-back-to-validation="goBackToValidationBackside"
            :isBevRegCreated="isBevRegCreated"
        />
    </div>
</template>

<script lang="ts">
import { isMobile } from 'mobile-device-detect'
import UploadView from './UploadView.vue'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import variables from '@/client-app/shared/variables'
import router from '@/router'
import UAParser from 'ua-parser-js'
import utils from '@/client-app/shared/mixins/utils'
import store from '@/store'
import { ZLBResponse } from '@/client-app/dto/CommonInterfaces'
import TutorialView from './TutorialView.vue'
import APIService from '@/client-app/shared/APIService'

export default defineComponent({
    beforeRouteLeave(to, from, next) {
        if (to.name === 'VerifyEmail' || to.name === 'Confirm' || to.name === 'CodeValidation') {
            next(false)
        } else {
            next()
        }
    },
    /* if Bev registration is completed then stay in Billing page for non b2b journey, or navigate to 
    FinishB2B page for b2b journey */
    beforeRouteEnter(to, from, next) {
        if (
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BEV_REGISTRATION_COMPLETED) ===
            'true'
        ) {
            if (window.location.href.includes('/b2b')) {
                next({ name: 'FinishB2B', query: router.currentRoute.value?.query })
            } else {
                next({ name: 'Billing', query: router.currentRoute.value?.query })
            }
        } else {
            next()
        }
    },
    components: { UploadView, TutorialView },
    setup() {
        interface Errors {
            zlb_scan: {
                error_code: string
                details: { given_width: string; given_height: string }
            }
        }
        const error = ref(false)
        const errorMessage = ref('')
        const loading = ref(false)
        const numberOfUploads = ref(0)
        const yearBevRegistration: Ref = ref({})
        const zlbConstrains: Ref = ref(null)

        // store full path of the routed url to use later when user wants to add more zlb images
        localStorage.setItem(
            variables.LOCAL_STORAGE_ITEMS.ROUTERPATH,
            router.currentRoute.value.fullPath
        )

        const photoAdded = (evt: Event) => {
            const element = evt?.target as HTMLInputElement

            if (element) {
                const file = element.files?.[0]

                if (file) {
                    sendZLB(file)
                }

                element.value = ''
            }
        }

        // repeatition codes of UploadView.vue and Tutorial.vue will be avoid with #208 refactoring task
        //get system constrain properties
        const getSystemConstraints = () => {
            APIService.getSystemConstraints()
                .then((response) => {
                    zlbConstrains.value = response.data?.bev_registration?.zlb_scan_constraints
                    loading.value = false
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Error while receiving data'
                })
        }

        const sendDeviceOSBrowserInfo = (id: number) => {
            const UA = new UAParser()
            const deviceDetails = UA.getResult()
            const data = {
                registration: id,
                device_type: deviceDetails?.device?.type ? 'mobile' : 'desktop',
                device_name: deviceDetails?.device?.model,
                browser_version: `${deviceDetails?.browser?.name} ${deviceDetails?.browser?.version}`,
                os_name: deviceDetails?.os?.name,
                os_version: deviceDetails?.os?.version,
                full_user_agent_string: deviceDetails?.ua,
            }
            APIService.sendDeviceInfo(data)
                .then(() => {
                    loading.value = false
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Error while uploading data'
                })
        }

        // display error messages
        const ZLBdisplayErrors = (e: {
            response: {
                data: Errors
            }
            errorMessage: string
        }) => {
            const errorCode = e.response.data && e.response.data.zlb_scan?.error_code
            if (e.errorMessage === 'submit-failed') {
                return
            }
            error.value = true
            // display server error messages
            if (!errorCode) {
                errorMessage.value =
                    'Leider konnte unser Algorithmus keinen zulässigen Fahrzeugschein erkennen.'
            } else {
                if (errorCode === 'IMGC001') {
                    const givenResolutions = e.response.data.zlb_scan?.details
                    errorMessage.value = `Die Bildauflösung muss größer als ${zlbConstrains.value?.min_size}x${zlbConstrains.value?.min_size} Pixel sein. Insgesamt darf das Bild nicht mehr als  ${zlbConstrains.value?.max_pixels} Pixel haben. Das hochgeladene Bild hat folgende Auflösung: ${givenResolutions?.given_width}x${givenResolutions?.given_height} Pixel`
                } else {
                    errorMessage.value = utils.getErrorMessage(errorCode)
                }
            }
        }

        // post success configurations
        const ZLBPostSuccess = (responseData: ZLBResponse[]) => {
            const bevRegistrations = responseData
            // an image has been uploaded
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.FRONT_IMAGE_SUCSESS, 'true')
            // get any year of registration
            if (bevRegistrations.length > 0) yearBevRegistration.value = bevRegistrations[0]
            // send the selected year bev registration's sequence and uuid
            if (yearBevRegistration.value) {
                sendDeviceOSBrowserInfo(yearBevRegistration.value?.id)

                store.commit('setQuoteId', yearBevRegistration.value.sequence)
                store.commit('setQuoteUUID', yearBevRegistration.value.uuid)
                // to be able to use in `Validation` component
                store.commit('setBevRegistrationId', yearBevRegistration.value?.id)
            }

            if (router.currentRoute.value.fullPath.includes('/b2b')) {
                router.push({
                    name: 'ValidationB2B',
                    query: router.currentRoute.value?.query,
                })
            } else {
                router.push({
                    name: 'Validation',
                    query: router.currentRoute.value?.query,
                })
            }
        }

        // after 3 fails, send the request with skip classifier `true`
        const sendNonZlbUpload = (formData: FormData) => {
            APIService.sendZLBUpload(formData)
                .then((response) => {
                    loading.value = false
                    ZLBPostSuccess(response.data)
                })
                .catch((e) => {
                    loading.value = false
                    ZLBdisplayErrors(e)
                })
        }

        // send zlb_scan to backend for OCR process
        const sendZLB = (file: File) => {
            const storedLSYearsChoices = store.state.tcAndPp
            const queries = router.currentRoute.value.query

            const formData = new FormData()
            formData.append('partner', store.state.partner)
            formData.append('zlb_scan', file)

            if (router.currentRoute.value.fullPath.includes('/b2b')) {
                formData.append('source', 'b2b_fe')
            } else {
                const registrationYears = []
                // #188 Legislation Period choice for registration
                queries.current_year === 'true' || storedLSYearsChoices?.currentYear
                    ? registrationYears.push('current_year')
                    : ''
                queries.next_year === 'true' || storedLSYearsChoices?.nextYear
                    ? registrationYears.push('next_year')
                    : ''
                formData.append('registration_years', registrationYears.join(','))
            }
            loading.value = true
            APIService.sendZLBUpload(formData)
                .then((response) => {
                    loading.value = false
                    ZLBPostSuccess(response.data)
                })
                .catch((e) => {
                    if (numberOfUploads.value >= 2) {
                        formData.append('skip_classifier', 'True')
                        sendNonZlbUpload(formData)
                    } else {
                        loading.value = false
                    }
                    numberOfUploads.value += 1
                    ZLBdisplayErrors(e)
                })
        }

        // go back to backside validation page when user cancel
        const goBackToValidationBackside = () => {
            if (router.currentRoute.value.fullPath.includes('/b2b')) {
                router.push({ name: 'ValidationB2B', query: router.currentRoute.value?.query })
            } else {
                router.push({ name: 'Validation', query: router.currentRoute.value?.query })
            }
        }

        onMounted(() => getSystemConstraints())

        return {
            isMobile,
            showDot: store.state.config.display_title_dot,
            utils,
            error,
            errorMessage,
            router,
            photoAdded,
            loading,
            goBackToValidationBackside,
            isBevRegCreated: !!store.state.bevRegistrationId,
        }
    },
})
</script>

<style></style>
