import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f1d2370"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-wrapper" }
const _hoisted_2 = { class: "details-wrapper grid" }
const _hoisted_3 = { class: "col-12 lg:col-5" }
const _hoisted_4 = { class: "detail-bundle grid" }
const _hoisted_5 = { class: "title title-wrapper col-4" }
const _hoisted_6 = { class: "value col-8" }
const _hoisted_7 = { class: "detail-bundle grid" }
const _hoisted_8 = { class: "title title-wrapper col-4" }
const _hoisted_9 = { class: "value col-8" }
const _hoisted_10 = { class: "detail-bundle grid" }
const _hoisted_11 = { class: "title title-wrapper col-4" }
const _hoisted_12 = { class: "value col-8" }
const _hoisted_13 = {
  key: 0,
  class: "detail-bundle grid"
}
const _hoisted_14 = { class: "title title-wrapper col-4" }
const _hoisted_15 = { class: "value col-8" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 2,
  class: "client-fee-breakdown mt-1"
}
const _hoisted_19 = { class: "grid" }
const _hoisted_20 = { class: "label col-4" }
const _hoisted_21 = { class: "value col-8" }
const _hoisted_22 = { class: "grid" }
const _hoisted_23 = { class: "label col-4" }
const _hoisted_24 = { class: "value col-8" }
const _hoisted_25 = { class: "grid" }
const _hoisted_26 = { class: "label col-4" }
const _hoisted_27 = { class: "value col-8" }
const _hoisted_28 = { class: "detail-bundle grid" }
const _hoisted_29 = { class: "title title-wrapper col-4" }
const _hoisted_30 = { class: "value col-8" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = {
  key: 0,
  class: "rejected-reason"
}
const _hoisted_33 = {
  key: 1,
  class: "sub-text"
}
const _hoisted_34 = { class: "col-12 lg:col-7" }
const _hoisted_35 = { class: "value image-wrapper" }
const _hoisted_36 = ["src"]
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "value resubmit-btn" }
const _hoisted_39 = {
  key: 0,
  class: "col-12"
}
const _hoisted_40 = { class: "grid" }
const _hoisted_41 = { class: "col-12 lg:col-6" }
const _hoisted_42 = { class: "section-title mt-2" }
const _hoisted_43 = { class: "detail-bundle grid" }
const _hoisted_44 = { class: "title title-wrapper col-4" }
const _hoisted_45 = { class: "value col-8" }
const _hoisted_46 = { class: "detail-bundle grid" }
const _hoisted_47 = { class: "title title-wrapper col-4" }
const _hoisted_48 = { class: "value col-8" }
const _hoisted_49 = { class: "detail-bundle grid" }
const _hoisted_50 = { class: "title title-wrapper col-4" }
const _hoisted_51 = { class: "value col-8" }
const _hoisted_52 = { class: "detail-bundle grid" }
const _hoisted_53 = { class: "title title-wrapper col-4" }
const _hoisted_54 = { class: "value col-8" }
const _hoisted_55 = { class: "detail-bundle grid" }
const _hoisted_56 = { class: "title title-wrapper col-4" }
const _hoisted_57 = { class: "value col-8" }
const _hoisted_58 = { class: "detail-bundle grid" }
const _hoisted_59 = { class: "title title-wrapper col-4" }
const _hoisted_60 = { class: "value col-8" }
const _hoisted_61 = { class: "detail-bundle grid" }
const _hoisted_62 = { class: "title title-wrapper col-4" }
const _hoisted_63 = { class: "value col-8" }
const _hoisted_64 = { class: "detail-bundle grid" }
const _hoisted_65 = { class: "title title-wrapper col-4" }
const _hoisted_66 = { class: "value col-8" }
const _hoisted_67 = { class: "col-12 lg:col-6" }
const _hoisted_68 = { key: 0 }
const _hoisted_69 = { class: "section-title mt-2" }
const _hoisted_70 = { class: "detail-bundle grid" }
const _hoisted_71 = { class: "title title-wrapper col-4" }
const _hoisted_72 = { class: "value col-8" }
const _hoisted_73 = { class: "detail-bundle grid" }
const _hoisted_74 = { class: "title title-wrapper col-4" }
const _hoisted_75 = { class: "value col-8" }
const _hoisted_76 = { class: "detail-bundle grid" }
const _hoisted_77 = { class: "title title-wrapper col-4" }
const _hoisted_78 = { class: "value col-8" }
const _hoisted_79 = { class: "section-title mt-2" }
const _hoisted_80 = { class: "detail-bundle grid" }
const _hoisted_81 = { class: "title title-wrapper col-4" }
const _hoisted_82 = { class: "value col-8" }
const _hoisted_83 = { class: "detail-bundle grid" }
const _hoisted_84 = { class: "title title-wrapper col-4" }
const _hoisted_85 = { class: "value col-8" }
const _hoisted_86 = { class: "m-0" }
const _hoisted_87 = { class: "term-list-eup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimelinePV = _resolveComponent("TimelinePV")!
  const _component_ButtonPV = _resolveComponent("ButtonPV")!
  const _component_CardPV = _resolveComponent("CardPV")!
  const _component_DropdownPV = _resolveComponent("DropdownPV")!
  const _component_TermsList = _resolveComponent("TermsList")!
  const _component_DialogPV = _resolveComponent("DialogPV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CardPV, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('registrations.block.qa_number')), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.item?.sequence), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('registrations.block.vehicle_type')), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.item?.registration?.vehicle_type), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('registrations.block.legislation_year')), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.item?.legislation_year), 1)
            ]),
            (_ctx.showClientFee)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('registrations.block.client_value')), 1),
                  _createElementVNode("div", _hoisted_15, [
                    (_ctx.item?.display_vat)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, " € " + _toDisplayString(_ctx.utils.formatDecimals(
                                            _ctx.item?.vat_details?.client_fee_after_vat
                                        )), 1))
                      : _createCommentVNode("", true),
                    (!_ctx.item?.display_vat)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, " € " + _toDisplayString(_ctx.utils.formatDecimals(_ctx.item?.client_fee)), 1))
                      : _createCommentVNode("", true),
                    (_ctx.item?.display_vat)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t(
                                                    'registrations.block.vat_details.basic_client_fee'
                                                )), 1),
                            _createElementVNode("div", _hoisted_21, " € " + _toDisplayString(_ctx.utils.formatDecimals(_ctx.item?.client_fee)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('registrations.block.vat_details.vat_percentage')), 1),
                            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.utils.formatDecimals(
                                                    _ctx.item?.vat_details?.vat_percentage
                                                )) + " % ", 1)
                          ]),
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('registrations.block.vat_details.vat_amount')), 1),
                            _createElementVNode("div", _hoisted_27, " € " + _toDisplayString(_ctx.utils.formatDecimals(_ctx.item?.vat_details?.vat_amount)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('registrations.block.status')), 1),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_TimelinePV, {
                  value: _ctx.item?.status_timeline,
                  class: "w-full mt-1"
                }, {
                  marker: _withCtx((slotProps) => [
                    _createElementVNode("span", {
                      class: _normalizeClass([
                                                'flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 qa-status',
                                                slotProps.item?.timeline !== _ctx.StatusTimelines.FUTURE
                                                    ? slotProps.item?.status
                                                    : 'future-status',
                                            ])
                    }, null, 2)
                  ]),
                  content: _withCtx((slotProps) => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                                                'qa-status qa-label',
                                                slotProps.item?.timeline !== _ctx.StatusTimelines.FUTURE
                                                    ? slotProps.item?.status
                                                    : 'future-status-text',
                                            ])
                    }, _toDisplayString(slotProps.item?.details?.title), 3),
                    (
                                                slotProps.item?.timeline === _ctx.StatusTimelines.CURRENT
                                            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.item?.details
                                                    ?.subtitles, (detail, index) => {
                            return (_openBlock(), _createElementBlock("ul", { key: index }, [
                              _createElementVNode("li", null, [
                                (
                                                            [
                                                                _ctx.QAStatus.MANUALLY_REJECTED,
                                                                _ctx.QAStatus.REJECTED_BY_AUTHORITY,
                                                                _ctx.QAStatus.REJECTED_BY_PLATFORM,
                                                                _ctx.QAStatus.REJECTED_BY_PARTNER,
                                                            ].includes(slotProps.item?.status)
                                                        )
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                      _createElementVNode("i", null, _toDisplayString(detail), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("small", _hoisted_33, _toDisplayString(detail), 1))
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["value"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", {
                onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.moveZoomImage && _ctx.moveZoomImage(...args)))
              }, [
                (
                                        _ctx.item?.registration?.zlb_scan ||
                                        _ctx.item?.registration?.resized_image
                                    )
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "zlb-image w-full",
                      src: 
                                        _ctx.item?.registration?.resized_image ||
                                        _ctx.item?.registration?.zlb_scan
                                    ,
                      alt: "",
                      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setImageOrientation && _ctx.setImageOrientation(...args)))
                    }, null, 40, _hoisted_36))
                  : _createCommentVNode("", true),
                (
                                        _ctx.item?.registration?.zlb_scan ||
                                        _ctx.item?.registration?.resized_image
                                    )
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      class: "zoom",
                      src: 
                                        _ctx.item?.registration?.resized_image ||
                                        _ctx.item?.registration?.zlb_scan
                                    ,
                      alt: "",
                      onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setImageOrientation && _ctx.setImageOrientation(...args)))
                    }, null, 40, _hoisted_37))
                  : _createCommentVNode("", true)
              ], 32)
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createVNode(_component_ButtonPV, {
                label: _ctx.$t('registrations.block.resubmit_registration'),
                class: "primary",
                onClick: _ctx.openModal,
                disabled: _ctx.availableYears.length === 0
              }, null, 8, ["label", "onClick", "disabled"])
            ])
          ]),
          (_ctx.showMore)
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("h5", _hoisted_42, _toDisplayString(_ctx.$t('registrations.block.client_detail.title')), 1),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t('registrations.block.client_detail.gender')), 1),
                      _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.item?.registration?.client?.gender), 1)
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$t('registrations.block.client_detail.firstname')), 1),
                      _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.item?.registration?.client?.first_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_49, [
                      _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t('registrations.block.client_detail.lastname')), 1),
                      _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.item?.registration?.client?.last_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t('registrations.block.client_detail.email')), 1),
                      _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.item?.registration?.client?.email), 1)
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.$t('registrations.block.client_detail.street_name')), 1),
                      _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.item?.registration?.client?.street_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t('registrations.block.client_detail.house_number')), 1),
                      _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.item?.registration?.client?.street_number), 1)
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t('registrations.block.client_detail.postcode')), 1),
                      _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.item?.registration?.client?.postal_code), 1)
                    ]),
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t('registrations.block.client_detail.city')), 1),
                      _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.item?.registration?.client?.city), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    (_ctx.item?.registration?.source !== 'B2C-FE')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                          _createElementVNode("h5", _hoisted_69, _toDisplayString(_ctx.$t('registrations.block.business_registrations.title')), 1),
                          _createElementVNode("div", _hoisted_70, [
                            _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t(
                                                    'registrations.block.business_registrations.company_name'
                                                )), 1),
                            _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.item?.registration?.client?.name_of_business), 1)
                          ]),
                          _createElementVNode("div", _hoisted_73, [
                            _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.$t(
                                                    'registrations.block.business_registrations.tax_exempt'
                                                )), 1),
                            _createElementVNode("div", _hoisted_75, [
                              _createElementVNode("span", {
                                class: _normalizeClass([
                                                    _ctx.item?.registration?.client?.is_vat_mandatory
                                                        ? 'greenText'
                                                        : 'redText',
                                                ])
                              }, _toDisplayString(_ctx.item?.registration?.client?.is_vat_mandatory
                                                        ? _ctx.$t('miscellaneous.Yes')
                                                        : _ctx.$t('miscellaneous.No')), 3)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_76, [
                            _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t(
                                                    'registrations.block.business_registrations.taxid'
                                                )), 1),
                            _createElementVNode("div", _hoisted_78, _toDisplayString(_ctx.item?.registration?.client?.vat_number), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("h5", _hoisted_79, _toDisplayString(_ctx.$t('registrations.block.billing_details.title')), 1),
                    _createElementVNode("div", _hoisted_80, [
                      _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t('registrations.block.billing_details.bank_account')), 1),
                      _createElementVNode("div", _hoisted_82, _toDisplayString(_ctx.item?.registration?.client?.account_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_83, [
                      _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.$t('registrations.block.billing_details.bank_number')), 1),
                      _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.utils.maskField(
                                                _ctx.item?.registration?.client?.account_iban
                                            )), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_ButtonPV, {
          class: "secondary w-full",
          label: _ctx.showBtnLabelName,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showMore = !_ctx.showMore)),
          outlined: ""
        }, null, 8, ["label"])
      ]),
      _: 1
    }),
    (_ctx.availableYears)
      ? (_openBlock(), _createBlock(_component_DialogPV, {
          key: 0,
          header: _ctx.$t('frontend_config.colors_tab.help_modal_title'),
          visible: _ctx.isOpenModal,
          style: { width: '50vw' },
          modal: true,
          draggable: false,
          dismissableMask: true
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_ButtonPV, {
              label: _ctx.$t('miscellaneous.Cancel'),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancelPopup())),
              class: "light"
            }, null, 8, ["label"]),
            _createVNode(_component_ButtonPV, {
              label: _ctx.$t('registrations.block.resubmit'),
              onClick: _ctx.resubmit,
              class: "primary",
              disabled: _ctx.displayNoYearAvailableMessage
            }, null, 8, ["label", "onClick", "disabled"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_86, [
              _createVNode(_component_DropdownPV, {
                class: "dropdown-list",
                options: _ctx.availableYears,
                placeholder: _ctx.$t('registrations.block.legislation_year_placeholder'),
                modelValue: _ctx.selectedYear,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedYear) = $event)),
                onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.displayTnC = true))
              }, null, 8, ["options", "placeholder", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_87, [
              (_ctx.displayTnC)
                ? (_openBlock(), _createBlock(_component_TermsList, {
                    key: 0,
                    ref: "termsListRef",
                    selectedYear: _ctx.selectedYear,
                    onTermsClickHandler: _ctx.termsAndPrivacyClickHandler,
                    onPrivacyClickHandler: _ctx.termsAndPrivacyClickHandler,
                    isEup: true
                  }, null, 8, ["selectedYear", "onTermsClickHandler", "onPrivacyClickHandler"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["header", "visible"]))
      : _createCommentVNode("", true)
  ]))
}