import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "login-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "logo-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.logo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: `${_ctx.logo}`,
            id: "logo"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass([
                    'right-block',
                    _ctx.$router.currentRoute.value.name === 'LoginView' ? 'login' : '',
                ])
      }, [
        _createVNode(_component_router_view)
      ], 2)
    ])
  ]))
}