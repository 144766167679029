// validate fields and return true if field has errors
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'
import errors from '@/end-user-portal/assets/data/errors.json'
interface ErrorMessages {
    [key: string]: string
}
const validateFields = (field: string | number | [], type: string) => {
    // Validate submitted email.
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const passwordRegex =
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()_+{}":;'[\]])^[a-zA-Z0-9~!@#$%^&*()_+{}":;'[\]]*$/
    const germanRegex = /^[0-9,.]*$/

    switch (type) {
        case 'string':
            return field === '' ? true : false
        case 'array':
            return Array.isArray(field) && field.length === 0 ? true : false
        case 'number':
            return field === 0 ? true : false
        case 'boolean':
            return !field ? true : false
        // email type validate
        case 'email':
            return !emailRegex.test(String(field).toLowerCase()) ? true : false
        case 'german_rules':
            return typeof field === 'string' && !germanRegex.test(field)
        case 'password':
            return typeof field === 'string' && passwordRegex.test(field)
        default:
            'type not found'
            return false
    }
}

// check for errors in forms
const hasErrors = (obj: ErrorObjectDTO) => {
    const errors = []
    for (const [key] of Object.entries(obj)) {
        errors.push(obj[key])
        for (const [, value] of Object.entries(obj[key])) {
            errors.push(value)
        }
    }
    //  return true if errors contains in any of fields
    return errors.includes(true)
}

// change date fromat to dd/mm/yyyy
const changeDateFormat = (date: string) => {
    return new Date(date).toLocaleString('en-GB').split(',')[0]
}

// convert decimals to display by german rules
const formatDecimals = (
    number: string | number | undefined,
    minimumDecimalPoints = 2,
    maxDecimalPoints = 4
) => {
    // setting minimum and maximum decimal points
    const options = {
        minimumFractionDigits: minimumDecimalPoints,
        maximumFractionDigits: maxDecimalPoints,
    }
    if (number) return Number(number).toLocaleString('de-DE', options)
    else return Number('0').toLocaleString('de-DE', options)
}

// revert german rules from decimal numbers
const revertFormattedGermanRule = (value: string): number | string => {
    if (!value) {
        return 0
    }
    // when the value has both "." and ","
    if (value.includes('.') && value.includes(',')) {
        return parseFloat(value.replace(/\./g, '').replace(',', '.'))
    } else if (value.includes('.') || value.includes(',')) {
        // if value has . in it
        if (value.includes('.')) {
            const [, decimalPart] = value.split('.')
            // length of decimal part is not upto 3 degits, then it remains as decimal points
            if (decimalPart.length < 3) {
                return value
            } else {
                /* if the decimal value length is 3 or more, they are 1000+ values as per german rules. 
            Hence they are converting to english numbers */
                return Number(value.replace('.', '')).toFixed(4)
            }
        }
        // if value has , in it (decimal points as per german rules)
        let revertedNumber = 0
        if (value.includes(',')) {
            // validate number with german rule
            let [integerPart, decimalPart] = value.split(',')
            // if there are no decimal points after `,`, add at least one decimal point
            if (decimalPart.length === 0) {
                decimalPart = '0'
            }
            // if there are no integer values mentioned before `,`, add at least one integer
            if (integerPart.length === 0) {
                integerPart = '0'
            }
            // above is to prepare the number to be valid as a german number to do the conversion below
            const integer = parseInt(integerPart.replace(/\./g, ''))
            const decimal = parseInt(decimalPart)
            // convert to english number from german number
            revertedNumber = integer + decimal / Math.pow(10, decimalPart.length)
        }
        return revertedNumber.toFixed(4)
    } else {
        return value
    }
}

/**
 * mask number to show only last 4 digits and replace others with 'x'
 * @param input string
 * @param charsToKeep number
 * @returns string
 * @example maskField('de12345678') // 'xxxxxx5678'
 */
function maskField(input: string, charsToKeep = 4): string {
    if (charsToKeep >= input.length) {
        return 'x'.repeat(input.length) // If charsToKeep is greater or equal to the length, replace all characters with 'x'
    }

    const prefix = 'x'.repeat(input.length - charsToKeep)
    const suffix = input.slice(-charsToKeep)

    return prefix + suffix
}

/**
 * convert percentage to color
 * Start end values:
 *  0 - red
 *  60 - yellow
 *  120 - green
 *  180 - turquoise
 *  240 - blue
 *  300 - pink
 *  360 - red
 * @param percent number
 * @param startColor
 * @param endColor
 * @param exceedColor
 * @returns hcl color value
 */
const hslColorPercentage = (
    percent: number,
    startColor: number,
    endColor: number,
    exceedColor?: number
): string => {
    const a = percent / 100,
        b = (endColor - startColor) * a,
        c = typeof exceedColor === 'number' && percent / 100 > 1 ? exceedColor : b + startColor

    // Return a CSS HSL string
    return 'hsl(' + c + ', 100%, 40%)'
}

// get error messages translation key mapped to the specific error code
const getErrorMessageTranslationKey = (errorCode: string) => {
    const errorCodes: ErrorMessages = errors
    return errorCodes[errorCode]
}

export default {
    validateFields,
    hasErrors,
    changeDateFormat,
    formatDecimals,
    revertFormattedGermanRule,
    maskField,
    hslColorPercentage,
    getErrorMessageTranslationKey,
}
