import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7434f3b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "field" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "form-error" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "form-error"
}
const _hoisted_10 = {
  key: 1,
  class: "form-error"
}
const _hoisted_11 = {
  key: 0,
  class: "field-checkbox"
}
const _hoisted_12 = { class: "field login-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxPV = _resolveComponent("CheckboxPV")!
  const _component_ButtonPV = _resolveComponent("ButtonPV")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-msg-wrapper", [_ctx.isFormMsg ? 'show' : '', _ctx.isError ? 'error' : 'success']])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.formErrorContent), 1)
    ], 2),
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('signup.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('signup.description')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submitSignup()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          id: "username",
          type: "text",
          class: "inputfield w-full",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.SIGNUP_FORM.USERNAME) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.SIGNUP_FORM.USERNAME]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          id: "password",
          type: "password",
          class: _normalizeClass(["inputfield w-full", [_ctx.v$.PASSWORD.$dirty && _ctx.v$.PASSWORD.required.$invalid ? 'error' : '']]),
          placeholder: [
                        _ctx.v$.PASSWORD.$dirty && _ctx.v$.PASSWORD.required.$invalid
                            ? _ctx.$t('miscellaneous.error_msgs.required')
                            : _ctx.$t('miscellaneous.placeholders.password'),
                    ],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.SIGNUP_FORM.PASSWORD) = $event)),
          onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$.PASSWORD.$validate && _ctx.v$.PASSWORD.$validate(...args)))
        }, null, 42, _hoisted_5), [
          [_vModelText, _ctx.SIGNUP_FORM.PASSWORD]
        ]),
        (_ctx.v$.PASSWORD.$dirty && _ctx.v$.PASSWORD.required.$invalid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('password_reset.new_password_required_msg')), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.v$.PASSWORD.$dirty && _ctx.v$.PASSWORD.minLength.$invalid)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('password_reset.new_password_eight_characters_msg')), 1))
                : _createCommentVNode("", true),
              (
                            _ctx.v$.PASSWORD.$dirty && _ctx.v$.PASSWORD.containsPasswordRequirement.$invalid
                        )
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('password_reset.new_password_pattern_msg')), 1))
                : _createCommentVNode("", true)
            ]))
      ]),
      (_ctx.storedEmailVerificationDetails.is_unknown_user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_CheckboxPV, {
              modelValue: _ctx.SIGNUP_FORM.TERMS,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.SIGNUP_FORM.TERMS) = $event)),
              binary: true
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('signup.terms_pre')) + " ", 1),
              _createElementVNode("a", {
                style: {"cursor":"pointer"},
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'TermsAndConditions' })))
              }, _toDisplayString(_ctx.$t('miscellaneous.terms_and_conditions')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('signup.terms_post')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_ButtonPV, {
          label: _ctx.$t('signup.button_signup'),
          class: "button-raised w-full primary",
          type: "submit",
          disabled: _ctx.disableSignup
        }, null, 8, ["label", "disabled"])
      ])
    ], 32)
  ]))
}