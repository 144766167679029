<template>
    <div class="faq view-root">
        <small @click="router.go(-1)"
            ><a><i class="fas fa-angle-left mr-2"></i>Zurück</a></small
        >
        <div class="video-wrapper">
            <iframe
                frameborder="0"
                allowfullscreen="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Tutorial"
                width="500"
                height="300"
                :src="youtubeVideoLink"
            >
            </iframe>
        </div>

        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            FAQ
        </div>
        <div class="accordion-wrapper mt-3 mb-3">
            <Accordion>
                <AccordionTab v-for="tab in data.tabs" :key="tab.title" :header="tab.title">
                    <p v-html="tab.content" />
                </AccordionTab>
            </Accordion>
        </div>
        <div class="action-wrapper">
            <ButtonPV label="Zurück" class="primary w-full" @click="router.go(-1)" />
        </div>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent, ref } from 'vue'
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'
import commonUtils from '@/shared/mixins/utils'
interface FAQ {
    title: string
    content: string
}

export default defineComponent({
    setup() {
        const youtubeVideoLink = ref({})

        const faqs: FAQ[] = []
        // faq.answer is a b64 encoded string so that it can contain single and double quotes.
        for (const faq of store.state.config.pages.faq) {
            faqs.push({ title: faq.question, content: commonUtils.b64DecodeUnicode(faq.answer) })
        }
        const FAQYoutubeVideoLink = store.state.config.faq_youtube_video_link

        if (FAQYoutubeVideoLink) {
            const meta = document.createElement('meta')
            meta.httpEquiv = 'Content-Security-Policy'
            meta.content = '.....; frame-src youtube.com youtu.be https://www.youtube.com;'
            document.getElementsByTagName('head')[0].appendChild(meta)
        }

        youtubeVideoLink.value = FAQYoutubeVideoLink
        const data = ref({
            active: 0,
            tabs: faqs,
        })
        return {
            showDot: store.state.config.display_title_dot,
            videoId:
                FAQYoutubeVideoLink !== null
                    ? FAQYoutubeVideoLink.substring(FAQYoutubeVideoLink.lastIndexOf('/') + 1)
                    : '',
            youtubeVideoLink,
            router,
            data,
            utils,
        }
    },
})
</script>

<style scoped lang="scss">
#list {
    list-style-type: none;
    padding: 0px;
}

.p-component {
    font-family: var(--tqs-font-family);
}
</style>
