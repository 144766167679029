import { createApp } from 'vue'

// Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

// PrimeVue
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Flickity from 'vue-flickity'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'

import Avatar from 'primevue/avatar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Paginator from 'primevue/paginator'
import TabMenu from 'primevue/tabmenu'
import Tooltip from 'primevue/tooltip'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import BreadcrumbSection from '@/end-user-portal/shared/components/BreadcrumbSection.vue'
import 'nprogress/nprogress.css'
import Timeline from 'primevue/timeline'
import VOtpInput from 'vue3-otp-input'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App.vue'
import store from './store'
import i18n from '@/end-user-portal/i18n/index'
import DataPrivacy from '@/client-app/components/DataPrivacy.vue'
import ImpressumCmp from '@/client-app/components/ImpressumCmp.vue'
// import setupInterceptors from './shared/axios/SetupInterceptors'
import router from './router'
import Loading from 'vue3-loading-overlay'
import setupInterceptors from '@/end-user-portal/shared/axios/SetupInterceptors'
// Styles
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/mdc-light-indigo/theme.css'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/assets/styles/styles.scss'
import RadioButton from 'primevue/radiobutton'

const app = createApp(App)
setupInterceptors(store)
app.use(store)
app.use(router)
app.use(i18n)
app.use(PrimeVue)

app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
})

if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== 'localhost') {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [
                    'localhost',
                    'develop-portal.thg-quoten.de',
                    'staging-portal.thg-quoten.de',
                    'portal.thg-quoten.de',
                    /^\//,
                ],
            }),
        ],
        /* Set tracesSampleRate to 1.0 to capture 100%
        of transactions for performance monitoring.
        We recommend adjusting this value in production
        Set to 0.0, because we don't use sentry (yet) to check performance/error rates */
        tracesSampleRate: 0.0,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    })
}

app.component('OtpInput', VOtpInput)

// PrimeVue
app.component('ButtonPV', Button)
app.component('AccordionPV', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('InputText', InputText)
app.component('DropdownPV', Dropdown)
app.component('CheckboxPV', Checkbox)
app.component('RadioPV', RadioButton)
app.component('FlickityPV', Flickity)
app.component('ImpressumCmp', ImpressumCmp)
app.component('DataPrivacy', DataPrivacy)
app.component('DialogPV', Dialog)
app.component('VueLoading', Loading)
app.component('AvatarPV', Avatar)
app.component('BreadcrumbSection', BreadcrumbSection)
app.component('DataTable', DataTable)
app.component('ColumnPV', Column)
app.component('PaginatorPV', Paginator)
app.component('TabMenu', TabMenu)
app.directive('Tooltip', Tooltip)
app.component('CalendarPV', Calendar)
app.component('CardPV', Card)
app.component('TimelinePV', Timeline)
app.component('MessagePV', Message)

app.mount('#app')
