import axiosInstance from '@/shared/axios/AxiosInstance'
import utils from '@/shared/mixins/utils'
import variables from './variables'
import { BillingRequestDTO, deviceInfoRequestDTO } from '@/client-app/dto/CommonInterfaces'
import { LocationQueryValue } from 'vue-router'

const defaultHeaders = {
    headers: {
        'X-CLIENT-DOMAIN': 'end_user_portal',
        'X-SUBDOMAIN': utils.subdomain,
    },
}

export default {
    billingSubmit(data: BillingRequestDTO) {
        return axiosInstance.post(variables.URLS.clients, data, defaultHeaders)
    },
    validateVatNumber(data: FormData) {
        return axiosInstance.post(variables.URLS.vat_validation, data, defaultHeaders)
    },
    imageLock(bevRegistrationId: number) {
        return axiosInstance.post(
            `${variables.URLS.bev_registrations}${bevRegistrationId}/lock/`,
            {},
            { ...defaultHeaders, params: { update_related: 'True' } }
        )
    },
    lockClient(clientId: number) {
        return axiosInstance.post(`${variables.URLS.clients}${clientId}/lock/`, {}, defaultHeaders)
    },
    getSystemConstraints() {
        return axiosInstance.get(variables.URLS.system_constraints, defaultHeaders)
    },
    sendDeviceInfo(data: deviceInfoRequestDTO) {
        return axiosInstance.post(variables.URLS.fe_user_agent_data, data, defaultHeaders)
    },
    sendZLBUpload(data: FormData) {
        return axiosInstance.post(variables.URLS.bev_registrations, data, defaultHeaders)
    },
    returnUrlVerify(data: { return_url: string | LocationQueryValue[] }) {
        return axiosInstance.post(variables.URLS.return_url_verify, data)
    },
    verifyOTP(data: { otp_code: string; email: string }) {
        return axiosInstance.post(variables.URLS.verify_opt, data)
    },
    loginOTP(data: {
        email: string
        partner?: string | undefined
        verify_email_url?: string | undefined
    }) {
        return axiosInstance.post(variables.URLS.login_otp, data)
    },
    loginEmail(data: {
        email: string
        partner?: string | undefined
        verify_email_url?: string | undefined
    }) {
        return axiosInstance.post(variables.URLS.login_email, data)
    },
    verifyEmail(data: { key: LocationQueryValue | LocationQueryValue[] }) {
        return axiosInstance.post(variables.URLS.verify_email, data)
    },
}
