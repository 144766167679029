<template>
    <div class="side-bar-wrapper-inner">
        <div class="top-section">
            <div class="logo-wrapper">
                <img :src="companyLogo" alt="" />
            </div>
            <div class="menu-item-wrapper">
                <ul class="menu-item">
                    <li>
                        <router-link
                            to="/portal/registrations"
                            class="dropdown-item"
                            v-tooltip.right="{
                                value: $t('menu.registrations'),
                                class: 'menu-tooltip',
                                disabled: hideTooltip(),
                            }"
                        >
                            <i class="pi pi-users"></i>
                            <span>{{ $t('menu.registrations') }}</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="menu-item menu-bottom" v-if="showImpressum || showPrivacy">
                    <li v-if="showImpressum">
                        <router-link
                            to="/portal/impressum"
                            class="dropdown-item"
                            v-tooltip.right="{
                                value: $t('menu.impressum'),
                                class: 'menu-tooltip',
                                disabled: hideTooltip(),
                            }"
                        >
                            <i class="pi pi-ticket"></i>
                            <span>{{ $t('menu.impressum') }}</span>
                        </router-link>
                    </li>
                    <li v-if="showPrivacy">
                        <router-link
                            to="/portal/data-privacy-info"
                            class="dropdown-item"
                            v-tooltip.right="{
                                value: $t('menu.data-privacy-info'),
                                class: 'menu-tooltip',
                                disabled: hideTooltip(),
                            }"
                        >
                            <i class="pi pi-lock"></i>
                            <span>{{ $t('menu.data-privacy-info') }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom-section">
            <div class="menu-collapse">
                <button class="menu-toggle" @click.stop="toggleMenu">
                    <i class="pi pi-angle-double-left"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
    props: {
        isSuperPartner: {
            type: Boolean,
        },
    },
    setup() {
        const innerWidth = ref(window.innerWidth)
        const companyLogo = computed(() => store.state.config.logo)

        // Show link if show_impressum config is enabled and impressum_details text is not empty
        const showImpressum = computed(
            () => store.state.config.show_impressum && store.state.config.impressum_details
        )
        // show link if show_data_privacy config is enabled and privacydetails text is not empty
        const showPrivacy = computed(
            () =>
                store.state.config.show_data_privacy && store.state.config.pages.privacydetails.text
        )

        // Toggle menu open close state
        const toggleMenu = () => {
            store.commit('SET_MENU_IS_OPEN', !store.state.menuIsOpened)
        }

        // compute hideTooltip value
        const hideTooltip = () => innerWidth.value > 1500 || store.state.menuIsOpened

        const onWindowResize = () => {
            // update window inner width
            innerWidth.value = window.innerWidth
        }

        onMounted(() => {
            // Listen to window resize event
            window.addEventListener('resize', onWindowResize)
        })

        onUnmounted(() => {
            // Remove window resize event listener
            window.removeEventListener('resize', onWindowResize)
        })

        return {
            toggleMenu,
            hideTooltip,
            companyLogo,
            showImpressum,
            showPrivacy,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';

.side-bar-wrapper-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .top-section {
        display: flex;
        flex-flow: column;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 30px 10px 10px;
        overflow-x: hidden;

        .logo-wrapper {
            background-image: linear-gradient(90deg, $logo-bg-color-left, $logo-bg-color-right);
            border-radius: 10px;
            padding: 10px;

            img {
                width: 80px;
                border-radius: 6px;
            }
        }

        .menu-item-wrapper {
            display: flex;
            flex-flow: column;
            height: 100%;
            padding-top: 20px;

            .menu-item {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 5px;

                    a {
                        display: flex;
                        text-align: left;
                        justify-content: flex-start;
                        height: 68px;
                        text-decoration: none;
                        padding: 13px 10px;
                        font-size: rem(14px);
                        // align-items: center;
                        flex-direction: row;
                        font-weight: 700;
                        white-space: normal;
                        display: flex;
                        align-items: center;

                        img {
                            width: 23px;
                            margin-right: 5px;
                        }

                        i {
                            font-size: rem(18px);
                            padding-right: 5px;
                        }

                        span {
                            text-align: left;
                        }

                        &:hover {
                            background-image: linear-gradient(
                                90deg,
                                $primary-color-left,
                                $primary-color-right
                            );
                            border-radius: 10px;
                            color: $white !important;

                            i {
                                color: $white !important;
                            }
                        }

                        &:focus {
                            background-color: none;
                        }
                    }

                    .dropdown-item.router-link-active {
                        background-image: linear-gradient(
                            90deg,
                            $primary-color-left,
                            $primary-color-right
                        );
                        border-radius: 10px;
                        color: $white !important;

                        i {
                            color: $white !important;
                        }

                        img {
                            filter: invert(1);
                        }
                    }

                    &:hover {
                        color: $white !important;
                        background-image: linear-gradient(
                            90deg,
                            $primary-color-left,
                            $primary-color-right
                        );

                        i {
                            color: $primary-color;
                        }

                        img {
                            filter: invert(1);
                        }
                    }
                }

                li:hover {
                    border-radius: 10px;
                }

                &.menu-bottom {
                    margin-top: auto;
                }
            }
        }
    }
    .bottom-section {
        padding: 10px;
        border-top: 1px solid #e6e6e6;
        background-color: #f6f6f6;
    }
}
</style>
