<template>
    <div id="desktop">
        <!-- only available for E.on-wallbox partner -->
        <div class="eon-header-image-wrapper" v-if="commonUtils.isEONWallbox">
            <img
                class="eon-header-image"
                src="@/client-app/assets/eon/first_page_header.svg"
                alt="eon header"
            />
        </div>
        <div class="title-text">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            THG-Quoten Service
        </div>
        <p>
            Haben Sie bereits ein Bild Ihrer Zulassungsbescheinigung auf Ihrem PC oder Tablet
            gespeichert?
        </p>
        <p><b>Ja:</b> Dann klicken Sie bitte einfach auf den Weiter Button.</p>
        <div class="action-wrapper mt-5 mb-5">
            <ButtonPV
                label="Weiter auf Desktop-Computer"
                class="primary"
                @click="submit()"
                type="submit"
                id="welcome-desktop"
            />
        </div>
        <p>
            <b>Nein:</b> Dann scannen Sie bitte den QR-Code mit Ihrem Smartphone, um die
            Registrierung auf Ihrem Handy zu starten.
        </p>
        <div id="canvas" class="mb-4"></div>
        <p>Vielen Dank, dass Sie den THG-Quoten Service verwenden.</p>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent, onMounted } from 'vue'
import QRCodeStyling from 'qr-code-styling'
import router from '@/router'
import commonUtils from '@/shared/mixins/utils'
import variables from '@/client-app/shared/variables'

export default defineComponent({
    setup() {
        // get current accesed url
        const value = window.location.href
        localStorage.clear()

        onMounted(() => {
            const qrCode = new QRCodeStyling({
                width: 300,
                height: 300,
                type: 'svg',
                data: value,
                dotsOptions: {
                    type: 'extra-rounded',
                    color: '#000000',
                },
                cornersSquareOptions: {
                    type: 'extra-rounded',
                    color: '#000000',
                },
                cornersDotOptions: {
                    color: '#000000',
                },
                backgroundOptions: {
                    color: '#ffffff',
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                },
                qrOptions: {
                    typeNumber: 0,
                    mode: 'Byte',
                    errorCorrectionLevel: 'Q',
                },
            })
            const canvas = document.getElementById('canvas')
            canvas && qrCode.append(canvas)
        })

        const submit = () => {
            if (router.currentRoute.value.fullPath.includes('/b2b')) {
                router.push({ name: 'WelcomeB2B', query: router.currentRoute.value?.query })
            } else {
                router.push({ name: 'WelcomeDesktop', query: router.currentRoute.value?.query })
            }
        }

        return {
            showDot: store.state.config.display_title_dot,
            value,
            submit,
            commonUtils,
            variables,
        }
    },
})
</script>

<style scoped lang="scss">
.action-wrapper {
    margin: auto;
    width: 50%;
    text-align: center;
}
#desktop {
    display: 'flex';
    justify-content: 'center';
    text-align: center;
}
#qrCode {
    display: inline-block;
    color: var(--tqs-button-color-left);
}
.Button {
    width: 50vw;
}
</style>
