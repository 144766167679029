import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d5b2a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy view-root" }
const _hoisted_2 = {
  id: "zuruck",
  class: "mb-5"
}
const _hoisted_3 = { id: "termstext" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "action-wrapper mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonPV = _resolveComponent("ButtonPV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("small", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.go(-1)))
      }, "< Zurück")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", { innerHTML: _ctx.text }, null, 8, _hoisted_4),
      _createElementVNode("p", null, [
        (_ctx.documentURL)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.documentURL
            }, "Online-Datenschutzinformation herunterladen", 8, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_ButtonPV, {
        label: "Zurück",
        class: "primary w-full",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.go(-1)))
      })
    ])
  ]))
}