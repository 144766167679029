<template>
    <div class="code-validation view-root">
        <div>
            <div :class="utils.getTitleClass()">
                <i v-if="showDot" class="fas fa-circle mr-2"></i>
                Bestätigungscode
            </div>
            <p class="mb-5">Geben Sie hier den per E-Mail versendeten Code ein.</p>
            <form>
                <div class="otp-wrapper mb-5">
                    <div class="mb-5">
                        <OtpInput
                            id="otp"
                            ref="otpInput"
                            input-classes="otp-input"
                            separator=""
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-complete="submit"
                            @on-change="onChange"
                        />
                    </div>
                    <p class="help-text">
                        Keine E-Mail erhalten? Überprüfen Sie bitte Ihren Spam-Ordner
                    </p>
                </div>
                <div class="mt-3 mb-3">
                    <small class="p-error" v-if="error">{{ errorMessage }}</small>
                </div>
            </form>
        </div>
        <!-- loading spinner -->
        <VueLoading
            :opacity="0.8"
            :active="loading"
            :can-cancel="true"
            :is-full-page="true"
            color="#778da0"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import router from '@/router'
import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'
import variables from '@/client-app/shared/variables'
import commonVariables from '@/shared/Variables'
import APIService from '@/client-app/shared/APIService'

export default defineComponent({
    beforeRouteLeave(to, from, next) {
        if (to.name == 'LoginCA') {
            next(false)
        } else {
            next()
        }
    },
    setup() {
        const otpValue = ref('')
        const error = ref(false)
        const loading = ref(false)
        const errorMessage = ref('')

        const onChange = () => {
            error.value = false
        }

        const submit = (value: string) => {
            otpValue.value = value
            error.value = false
            const data = {
                otp_code: otpValue.value,
                email: store.state.email,
            }
            loading.value = true
            APIService.verifyOTP(data)
                .then(({ data }) => {
                    // store tokens
                    const accessToken = data.access_token
                    const refreshToken = data.refresh_token
                    loading.value = false
                    if (accessToken && refreshToken) {
                        // if token is available set user as authenticated
                        localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.AUTHENTICATED, 'true')
                        localStorage.setItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN, accessToken)
                        localStorage.setItem(
                            commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN,
                            refreshToken
                        )
                        const b2b_suffix = router.currentRoute.value.fullPath.includes('b2b')
                            ? 'B2B'
                            : ''

                        router.push({
                            name: 'ImageUploadWrapper' + b2b_suffix,
                            query: router.currentRoute.value?.query,
                        })
                    }
                })
                .catch(() => {
                    loading.value = false
                    error.value = true
                    errorMessage.value = 'Ungültiger Code'
                })
        }

        return {
            utils,
            showDot: store.state.config.display_title_dot,
            submit,
            onChange,
            error,
            loading,
            errorMessage,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.otp-wrapper {
    #otp {
        justify-content: center;
    }
    .help-text {
        text-align: center;
        font-size: rem(12px);
        font-style: italic;
    }
}
.action-wrapper {
    text-align: center;
}
</style>
