<template>
    <div class="recaptcha-otp-flow">
        <div
            class="form-msg-wrapper"
            :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']"
        >
            <p>{{ formErrorContent }}</p>
        </div>
        <h2 class="title">{{ $t('otp.title') }}</h2>
        <p class="description">{{ $t('otp.description') }}</p>
        <div class="otp-wrapper">
            <OtpInput
                class="otp"
                v-model:value="otpCode"
                input-classes="otp-input"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="verifyRecaptchaOTP()"
            />
            <div class="field login-btn mt-3">
                <ButtonPV
                    :label="$t('otp.resend_btn')"
                    class="p-button-raised w-full primary"
                    type="submit"
                    @click="resendOtp()"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import { defineComponent, ref } from 'vue'
import variables from '@/end-user-portal/shared/constant/Variables'
import NProgress from 'nprogress'
import { useI18n } from 'vue3-i18n'

export default defineComponent({
    props: {
        userId: String,
    },
    setup(props) {
        const formErrorContent = ref('')
        const isFormMsg = ref(false)
        const isError = ref(false)
        const otpCode = ref('')
        const { t } = useI18n()

        const reset = () => {
            isError.value = false
            isFormMsg.value = false
            formErrorContent.value = ''
        }

        const verifyRecaptchaOTP = () => {
            reset()
            const data = {
                user_id: Number(props.userId),
                otp_code: otpCode.value,
            }
            AuthenticationService.verifyRecaptchaOTP(data)
                .then((response) => {
                    localStorage.setItem(
                        variables.localStorageItems.accessToken,
                        response.data.tokens.access
                    )
                    localStorage.setItem(
                        variables.localStorageItems.refreshToken,
                        response.data.tokens.refresh
                    )
                    router.push({ name: 'RegistrationsView' })
                })
                .catch(() => {
                    NProgress.done()
                    isError.value = true
                    isFormMsg.value = true
                    formErrorContent.value = t('otp.otp_verify_fail')
                })
        }

        const resendOtp = () => {
            reset()
            const data = {
                user_id: Number(props.userId),
            }
            AuthenticationService.resendRecaptchaOtpCode(data)
                .then(() => {
                    isFormMsg.value = true
                    otpCode.value = ''
                    formErrorContent.value = t('otp.success_otp_resend')
                })
                .catch(() => {
                    NProgress.done()
                    isError.value = true
                    isFormMsg.value = true
                    formErrorContent.value = t('otp.fail_otp_resend')
                })
        }

        return {
            resendOtp,
            verifyRecaptchaOTP,
            isError,
            formErrorContent,
            otpCode,
            isFormMsg,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

h2.title {
    margin-bottom: 15px;
    font-weight: 800;
}

.form-msg-wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top-right-radius: 20px;
    z-index: 99;

    p {
        margin: 0;
        color: $white !important;
    }

    &.show {
        display: block;
        -webkit-animation: hideErrorMsg 4s forwards;
        animation: hideErrorMsg 4s forwards;
    }

    &.error {
        background: $red;
    }

    &.success {
        background: $green;
    }
}

@keyframes hideErrorMsg {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes hideErrorMsg {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
