import AxiosInstance from '@/shared/axios/AxiosInstance'
import commonVariables from '@/shared/Variables'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import router from '@/router'
import NProgress from 'nprogress'
import { AxiosRequestConfig } from 'axios'
import { Store } from 'vuex'
import { Profile } from '@/end-user-portal/dto/profile'

interface ToasterDTO {
    showToaster: boolean
    isSuccess: boolean
    toasterContent: string
}

const globalErrorHandler = (
    store: Store<{ toaster: ToasterDTO; emailVerificationStatus: string; profileDetails: Profile }>
) => {
    let refreshTokenPromise: Promise<void> | string | null
    AxiosInstance.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            NProgress.start()
            const accessToken = localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN)
                ? localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN)
                : ''
            if (accessToken !== '' && config.headers) {
                config.headers['Authorization'] = `Bearer ${accessToken}`
            }
            if (config.headers) config.headers['accept-language'] = process.env.VUE_APP_LANGUAGE
            return config
        },
        (error) => {
            NProgress.done()
            return Promise.reject(error)
        }
    )

    AxiosInstance.interceptors.response.use(
        (response) => {
            if (!response.config.url?.includes('generate_upload_url')) {
                NProgress.done()
            }
            return response
        },
        (error) => {
            const originalRequest = error.config
            if (
                error.response.status === 401 &&
                !originalRequest._retry &&
                error.config.url !== '/auth/user/login/'
            ) {
                originalRequest._retry = true
                if (!refreshTokenPromise) {
                    const toasterProps = {
                        showToaster: true,
                        isSuccess: false,
                        toasterContent:
                            'Ihre aktuelle Sitzung ist abgelaufen. Bitte warten Sie, während wir Ihre Sitzung wiederherstellen',
                    }
                    store.commit('SET_TOASTER_VALUES', toasterProps)
                    const refreshToken = localStorage.getItem(
                        commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN
                    )
                        ? localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN)
                        : ''
                    const data = { refresh: refreshToken }
                    refreshTokenPromise = AuthenticationService.refreshAccessToken(data)
                        .then((response) => {
                            refreshTokenPromise = null
                            NProgress.start()

                            AxiosInstance.defaults.headers.common[
                                'Authorization'
                            ] = `Bearer ${response.data.access}`

                            localStorage.setItem(
                                commonVariables.LOCAL_STORAGE_ITEMS.TOKEN,
                                response.data.access
                            )
                            localStorage.setItem(
                                commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN,
                                response.data.refresh
                            )
                            setTimeout(() => {
                                NProgress.done()
                                const toasterProps = {
                                    showToaster: true,
                                    isSuccess: true,
                                    toasterContent: 'Ihre Sitzung wurde wiederhergestellt',
                                }
                                store.commit('SET_TOASTER_VALUES', toasterProps)
                            }, 2000)
                            setTimeout(() => {
                                location.reload()
                            }, 2500)
                        })
                        .catch(() => {
                            localStorage.clear()
                            router.push({ name: 'LoginView' })
                            NProgress.done()
                        })
                }
            } else {
                NProgress.done()
            }
            return Promise.reject(error)
        }
    )
}

export default globalErrorHandler
