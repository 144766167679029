<template>
    <div class="finish view-root">
        <!-- only available for E.on-wallbox partner -->
        <div class="eon-header-image-wrapper" v-if="commonUtils.isEONWallbox && !isMobile">
            <img
                class="eon-header-image"
                src="@/client-app/assets/eon/finished-page_header.svg"
                alt="eon header"
            />
        </div>
        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            {{ conf.title }}
        </div>
        <div id="iconwrapper">
            <img src="@/client-app/assets/GiftIcon.svg" id="gifticon" />
        </div>
        <p v-if="notB2B2C" v-html="text" :style="`text-align: ${alignment}`"></p>
        <!-- only available for E.on-wallbox partner -->
        <small v-if="isAutomaticRedirect" class="timer">
            <i class="fas fa-history mr-2"></i
            ><span
                >Wir leiten Sie in <b>{{ timerValue }} sekunden</b> automatisch weiter
            </span>
        </small>
        <!-- only available for E.on-wallbox partner -->
        <div v-if="isRedirectBtnAvailable" class="redirect-button mt-3">
            <ButtonPV
                label="Jetzt Paket bestellen"
                class="primary w-full"
                @click="redirectToUrl()"
            />
        </div>
        <!-- only available for E.on-wallbox partner -->
        <p v-if="commonUtils.isEONWallbox">
            <small>
                Ihre Registrierung für die E.ON Drive THG-Prämie ist abgeschlossen. Sie finden eine
                Bestätigungsnachricht in Ihrem E-Mail-Postfach. Im nächsten Schritt nehmen wir die
                Anmeldung der THG-Quote über das Umweltbundesamt vor. Sobald dieser Prozess
                abgeschlossen ist, senden wir Ihnen eine finale Bestätigung unter Angabe des
                voraussichtlichen Auszahlungszeitpunktes der E.ON Drive THG-Prämie per E-Mail zu.
            </small>
        </p>
    </div>
</template>

<script lang="ts">
import store from '@/store'
import { computed, defineComponent, onMounted, ref } from 'vue'
import utils from '@/client-app/shared/mixins/utils'
import router from '@/router'
import variables from '@/client-app/shared/variables'
import { isMobile } from 'mobile-device-detect'
import commonUtils from '@/shared/mixins/utils'

export default defineComponent({
    beforeRouteLeave(to, from, next) {
        if (to.name === 'ValidationB2B' || to.name === 'Billing') {
            next(false)
        } else {
            next()
        }
    },
    /* when user try to navigate to Finish page without completing client registration,
    navigate to TermsView page. For b2b journey, navigate to LoginCA page */
    beforeRouteEnter(to, from, next) {
        if (
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BEV_REGISTRATION_COMPLETED) ===
                'true' &&
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BILLING_SUCCESS) === 'true'
        ) {
            next()
        } else {
            localStorage.clear()
            // to reset store
            window.location.reload()
            if (window.location.href.includes('/b2b')) {
                next({
                    name: 'LoginCA',
                    query: router.currentRoute.value?.query,
                })
            } else {
                next({
                    name: 'TermsView',
                    query: router.currentRoute.value?.query,
                })
            }
        }
    },
    setup() {
        const timerValue = ref(store.state.config.pages.finish.countdown_seconds)
        const availableAutoRedirect = store.state.config.pages.finish.is_auto_redirect_enabled
        const redirectUrl =
            store.state.config.pages.finish.redirect_url +
            '?thgQuoteId=' +
            store.state.quoteId +
            '&thgQuoteUUID=' +
            store.state.quoteUUID
        const conf = store.state.config.pages.finish
        // finish.text is a b64 encoded string so that it can contain single and double quotes.
        const text = commonUtils.b64DecodeUnicode(store.state.config.pages.finish.text)

        const redirectToUrl = () => {
            window.location.href = redirectUrl
            localStorage.clear()
        }

        /* auto redirect function will be available if
            - redirect is enabled
            - redirect URL is available
            - timer value should be greater than 0
            - Only for E.on-wallbox partners
        */
        const isAutomaticRedirect = computed(() => {
            return (
                availableAutoRedirect &&
                redirectUrl &&
                store.state.config.pages.finish.countdown_seconds > 0 &&
                commonUtils.isEONWallbox
            )
        })

        /* redirect button will be available if
            - redirect URL is available
            - redirect btn is enabled
            - Only for E.on-wallbox partners
        */
        const isRedirectBtnAvailable = computed(() => {
            return (
                redirectUrl &&
                store.state.config.pages.finish.is_button_redirect_enabled &&
                commonUtils.isEONWallbox
            )
        })

        const countDownTimer = () => {
            // automatically redirect to url once count down is over(#187)
            if (timerValue.value === 0) {
                redirectToUrl()
                return
            }
            if (timerValue.value > 0) {
                setTimeout(() => {
                    timerValue.value -= 1
                    countDownTimer()
                }, 1000)
            }
        }

        const redirectionHandler = () => {
            // checking whether return url is exist and redirect
            if (variables.LOCAL_STORAGE_ITEMS.RETURN_URL in localStorage) {
                setTimeout(() => {
                    let returnURL = localStorage
                        .getItem(variables.LOCAL_STORAGE_ITEMS.RETURN_URL)!
                        .replace(/["']/g, '')
                    if (returnURL.indexOf('?') > -1) {
                        returnURL +=
                            '&thgQuoteId=' +
                            store.state.quoteId +
                            '&thgQuoteUUID=' +
                            store.state.quoteUUID
                    } else {
                        returnURL +=
                            '?thgQuoteId=' +
                            store.state.quoteId +
                            '&thgQuoteUUID=' +
                            store.state.quoteUUID
                    }
                    window.location.href = returnURL
                    localStorage.clear()
                }, 1500)
            } else if (isAutomaticRedirect.value) {
                countDownTimer()
            } else if (isRedirectBtnAvailable.value) {
                return
            } else {
                localStorage.clear()
                // to reset store
            }
        }

        onMounted(() => {
            redirectionHandler()
        })

        return {
            utils,
            showDot: store.state.config.display_title_dot,
            alignment: store.state.config.item_alignment,
            isAutomaticRedirect,
            isRedirectBtnAvailable,
            conf,
            text,
            notB2B2C: !router.currentRoute.value.fullPath.includes('/b2b'),
            timerValue,
            redirectToUrl,
            variables,
            isMobile,
            commonUtils,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

#iconwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(14px);
    margin-bottom: rem(14px);
}

#gifticon {
    width: 30%;
}
</style>
