<template>
    <div class="impressum view-root">
        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            Impressum
        </div>

        <div class="info">
            <span v-html="impressumDetails"> </span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'

export default defineComponent({
    setup() {
        const impressumDetails = ref()

        //#region functions that are scoped only to the script
        const setImpressumValues = () => {
            impressumDetails.value = store.state.config.impressum_details
        }
        //#endregion

        onMounted(async () => {
            setImpressumValues()
        })

        return {
            impressumDetails,
            showDot: store.state.config.display_title_dot,
            utils,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.info {
    margin-top: rem(28px);
    .impressum-field {
        padding: 5px;
        .title {
            margin-right: 5px;
            text-transform: uppercase;
        }
    }
}
</style>
