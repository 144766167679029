<template>
    <div class="welcome view-root">
        <!-- title-text is a common class for all the title -->
        <div :class="utils.getTitleClass()">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            THG-Quote registrieren
        </div>
        <p id="registrieren">Registrieren Sie einen Fahrzeugschein innerhalb von zwei Minuten</p>
        <div class="list-section">
            <div class="sub-text">In drei einfachen Schritten zur THG-Quote</div>
            <ul class="list">
                <li>
                    <span class="order-number mr-2">1</span>
                    <div class="listtext">E-Mail Adresse bestätigen</div>
                </li>
                <li>
                    <span class="order-number mr-2">2</span>
                    <div class="listtext">{{ upload_method_text }} des Fahrzeugscheins</div>
                </li>
                <li>
                    <span class="order-number p-mr-2">3</span>
                    <div class="listtext">Verbindlich abschließen</div>
                </li>
            </ul>
        </div>
        <div class="action-wrapper">
            <ButtonPV label="Start" @click="submit" class="primary w-full mb-2" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from '@/router'
import store from '@/store'
import utils from '@/client-app/shared/mixins/utils'
import { isMobile } from 'mobile-device-detect'

export default defineComponent({
    setup() {
        const submit = () => {
            router.push({ name: 'LoginB2B', query: router.currentRoute.value?.query })
        }
        return {
            router,
            showDot: store.state.config?.display_title_dot,
            conf: store.state.config?.pages?.welcome,
            by_thg_quoten_gmbh: store.state.config?.by_thg_quoten_gmbh,
            frontendActivated: store.state.config?.frontend_active,
            utils,
            submit,
            upload_method_text: isMobile ? 'Scan' : 'Hochladen',
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';

.welcome {
    .sub-text {
        font-weight: bold;
        line-height: 1.5 !important;
    }

    .fa-circle {
        font-size: rem(16px) !important;
        color: var(--tqs-circle-color);
    }
    .list-section {
        .list {
            padding-left: 0;
            text-align: left;
            li {
                display: flex;
                list-style: none;
                font-size: rem(14px);
                padding: 10px 0;
                line-height: 1.5;
                .order-number {
                    color: var(--tqs-circle-inner-text);
                    background-color: var(--tqs-circle-color);
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    padding: 4px 10px;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .listtext {
                    margin-left: rem(8px);
                }
            }
        }
    }

    .action-wrapper {
        text-align: center;
        .faq-nav {
            font-size: rem(12px);
        }
    }

    .impressum {
        display: flex;
        width: 100%;
        .impressum-left {
            flex: 1;
        }
    }
}
.p-component {
    font-family: var(--tqs-font-family);
}
</style>
