<template>
    <div class="block-wrapper">
        <CardPV>
            <template #content>
                <div class="details-wrapper grid">
                    <div class="col-12 lg:col-5">
                        <div class="detail-bundle grid">
                            <div class="title title-wrapper col-4">
                                {{ $t('registrations.block.qa_number') }}
                            </div>
                            <div class="value col-8">
                                {{ item?.sequence }}
                            </div>
                        </div>
                        <div class="detail-bundle grid">
                            <div class="title title-wrapper col-4">
                                {{ $t('registrations.block.vehicle_type') }}
                            </div>
                            <div class="value col-8">
                                {{ item?.registration?.vehicle_type }}
                            </div>
                        </div>
                        <div class="detail-bundle grid">
                            <div class="title title-wrapper col-4">
                                {{ $t('registrations.block.legislation_year') }}
                            </div>
                            <div class="value col-8">
                                {{ item?.legislation_year }}
                            </div>
                        </div>
                        <div class="detail-bundle grid" v-if="showClientFee">
                            <div class="title title-wrapper col-4">
                                {{ $t('registrations.block.client_value') }}
                            </div>
                            <div class="value col-8">
                                <span v-if="item?.display_vat">
                                    €
                                    {{
                                        utils.formatDecimals(
                                            item?.vat_details?.client_fee_after_vat
                                        )
                                    }}
                                </span>
                                <span v-if="!item?.display_vat">
                                    € {{ utils.formatDecimals(item?.client_fee) }}
                                </span>
                                <div class="client-fee-breakdown mt-1" v-if="item?.display_vat">
                                    <div class="grid">
                                        <div class="label col-4">
                                            {{
                                                $t(
                                                    'registrations.block.vat_details.basic_client_fee'
                                                )
                                            }}
                                        </div>
                                        <div class="value col-8">
                                            € {{ utils.formatDecimals(item?.client_fee) }}
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="label col-4">
                                            {{
                                                $t('registrations.block.vat_details.vat_percentage')
                                            }}
                                        </div>
                                        <div class="value col-8">
                                            {{
                                                utils.formatDecimals(
                                                    item?.vat_details?.vat_percentage
                                                )
                                            }}
                                            %
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="label col-4">
                                            {{ $t('registrations.block.vat_details.vat_amount') }}
                                        </div>
                                        <div class="value col-8">
                                            €
                                            {{
                                                utils.formatDecimals(item?.vat_details?.vat_amount)
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-bundle grid">
                            <div class="title title-wrapper col-4">
                                {{ $t('registrations.block.status') }}
                            </div>
                            <div class="value col-8">
                                <TimelinePV :value="item?.status_timeline" class="w-full mt-1">
                                    <!-- display future statuses in gray -->
                                    <template #marker="slotProps">
                                        <span
                                            :class="[
                                                'flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 qa-status',
                                                slotProps.item?.timeline !== StatusTimelines.FUTURE
                                                    ? slotProps.item?.status
                                                    : 'future-status',
                                            ]"
                                        >
                                        </span>
                                    </template>
                                    <template #content="slotProps">
                                        <!-- display future statuses in gray -->
                                        <div
                                            :class="[
                                                'qa-status qa-label',
                                                slotProps.item?.timeline !== StatusTimelines.FUTURE
                                                    ? slotProps.item?.status
                                                    : 'future-status-text',
                                            ]"
                                        >
                                            {{ slotProps.item?.details?.title }}
                                        </div>
                                        <!-- display details only for current statuses -->
                                        <div
                                            v-if="
                                                slotProps.item?.timeline === StatusTimelines.CURRENT
                                            "
                                        >
                                            <ul
                                                v-for="(detail, index) of slotProps.item?.details
                                                    ?.subtitles"
                                                :key="index"
                                            >
                                                <li>
                                                    <div
                                                        class="rejected-reason"
                                                        v-if="
                                                            [
                                                                QAStatus.MANUALLY_REJECTED,
                                                                QAStatus.REJECTED_BY_AUTHORITY,
                                                                QAStatus.REJECTED_BY_PLATFORM,
                                                                QAStatus.REJECTED_BY_PARTNER,
                                                            ].includes(slotProps.item?.status)
                                                        "
                                                    >
                                                        <i>{{ detail }}</i>
                                                    </div>
                                                    <small v-else class="sub-text">
                                                        {{ detail }}
                                                    </small>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </TimelinePV>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-7">
                        <div class="value image-wrapper">
                            <div @mousemove="moveZoomImage">
                                <img
                                    class="zlb-image w-full"
                                    :src="
                                        item?.registration?.resized_image ||
                                        item?.registration?.zlb_scan
                                    "
                                    alt=""
                                    @load="setImageOrientation"
                                    v-if="
                                        item?.registration?.zlb_scan ||
                                        item?.registration?.resized_image
                                    "
                                />

                                <img
                                    class="zoom"
                                    :src="
                                        item?.registration?.resized_image ||
                                        item?.registration?.zlb_scan
                                    "
                                    alt=""
                                    @load="setImageOrientation"
                                    v-if="
                                        item?.registration?.zlb_scan ||
                                        item?.registration?.resized_image
                                    "
                                />
                            </div>
                        </div>
                        <!-- disable resubmit button if there are no available legislation years to select -->
                        <div class="value resubmit-btn">
                            <ButtonPV
                                :label="$t('registrations.block.resubmit_registration')"
                                class="primary"
                                @click="openModal"
                                :disabled="availableYears.length === 0"
                            />
                        </div>
                    </div>
                    <div class="col-12" v-if="showMore">
                        <div class="grid">
                            <div class="col-12 lg:col-6">
                                <h5 class="section-title mt-2">
                                    {{ $t('registrations.block.client_detail.title') }}
                                </h5>

                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.gender') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.gender }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.firstname') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.first_name }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.lastname') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.last_name }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.email') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.email }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.street_name') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.street_name }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.house_number') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.street_number }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.postcode') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.postal_code }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.client_detail.city') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.city }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6">
                                <div v-if="item?.registration?.source !== 'B2C-FE'">
                                    <h5 class="section-title mt-2">
                                        {{ $t('registrations.block.business_registrations.title') }}
                                    </h5>

                                    <div class="detail-bundle grid">
                                        <div class="title title-wrapper col-4">
                                            {{
                                                $t(
                                                    'registrations.block.business_registrations.company_name'
                                                )
                                            }}
                                        </div>
                                        <div class="value col-8">
                                            {{ item?.registration?.client?.name_of_business }}
                                        </div>
                                    </div>
                                    <div class="detail-bundle grid">
                                        <div class="title title-wrapper col-4">
                                            {{
                                                $t(
                                                    'registrations.block.business_registrations.tax_exempt'
                                                )
                                            }}
                                        </div>
                                        <div class="value col-8">
                                            <span
                                                :class="[
                                                    item?.registration?.client?.is_vat_mandatory
                                                        ? 'greenText'
                                                        : 'redText',
                                                ]"
                                            >
                                                {{
                                                    item?.registration?.client?.is_vat_mandatory
                                                        ? $t('miscellaneous.Yes')
                                                        : $t('miscellaneous.No')
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="detail-bundle grid">
                                        <div class="title title-wrapper col-4">
                                            {{
                                                $t(
                                                    'registrations.block.business_registrations.taxid'
                                                )
                                            }}
                                        </div>
                                        <div class="value col-8">
                                            {{ item?.registration?.client?.vat_number }}
                                        </div>
                                    </div>
                                </div>
                                <h5 class="section-title mt-2">
                                    {{ $t('registrations.block.billing_details.title') }}
                                </h5>

                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.billing_details.bank_account') }}
                                    </div>
                                    <div class="value col-8">
                                        {{ item?.registration?.client?.account_name }}
                                    </div>
                                </div>
                                <div class="detail-bundle grid">
                                    <div class="title title-wrapper col-4">
                                        {{ $t('registrations.block.billing_details.bank_number') }}
                                    </div>
                                    <div class="value col-8">
                                        {{
                                            utils.maskField(
                                                item?.registration?.client?.account_iban
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <ButtonPV
                    class="secondary w-full"
                    :label="showBtnLabelName"
                    @click="showMore = !showMore"
                    outlined
                />
            </template>
        </CardPV>

        <DialogPV
            :header="$t('frontend_config.colors_tab.help_modal_title')"
            :visible="isOpenModal"
            :style="{ width: '50vw' }"
            :modal="true"
            :draggable="false"
            :dismissableMask="true"
            v-if="availableYears"
        >
            <p class="m-0">
                <DropdownPV
                    class="dropdown-list"
                    :options="availableYears"
                    :placeholder="$t('registrations.block.legislation_year_placeholder')"
                    v-model="selectedYear"
                    @change="displayTnC = true"
                />
            </p>
            <div class="term-list-eup">
                <TermsList
                    v-if="displayTnC"
                    ref="termsListRef"
                    :selectedYear="selectedYear"
                    @terms-click-handler="termsAndPrivacyClickHandler"
                    @privacy-click-handler="termsAndPrivacyClickHandler"
                    :isEup="true"
                />
            </div>
            <template #footer>
                <ButtonPV
                    :label="$t('miscellaneous.Cancel')"
                    @click="cancelPopup()"
                    class="light"
                />
                <ButtonPV
                    :label="$t('registrations.block.resubmit')"
                    @click="resubmit"
                    class="primary"
                    :disabled="displayNoYearAvailableMessage"
                />
            </template>
        </DialogPV>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, provide, Ref, ref } from 'vue'
import { useI18n } from 'vue3-i18n'
import store from '@/store'
import utils from '@/end-user-portal/shared/mixins/utils'
import commonUtils from '@/shared/mixins/utils'
import { QAStatus, StatusTimelines } from '@/end-user-portal/enum/qa-enum'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'
import TermsList from '@/shared/components/TermsList.vue'
import variables from '@/client-app/shared/variables'
import { TermsAndConditionsDTO } from '@/shared/dto/CommonDTOs'

export default defineComponent({
    props: {
        item: Object,
        events: Array,
        pageNumber: Number,
    },
    components: {
        TermsList,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const showMore = ref(false)

        const isOpenModal = ref(false)
        const selectedYear: Ref = ref(null)
        const availableYears = computed(() => props.item?.allowed_resubmit_years || [])
        const termsListRef: Ref = ref(null)
        const displayTnC = ref(false)
        const initialValues = {
            ADDITIONAL_REQUIREMENT: false,
            // mark terms check true by default only for Eon
            TERMS: commonUtils.useComputations().isEONPartner,
            PRIVACY: commonUtils.useComputations().isEONPartner,
            CURRENT_YEAR: false,
            NEXT_YEAR: false,
            EMAIL_PROMOTION: false,
        }
        const formModel = ref({ ...initialValues })

        const errors: Ref<ErrorObjectDTO> = ref({
            CURRENT_YEAR: { mandatory: false },
            NEXT_YEAR: { mandatory: false },
            ADDITIONAL_REQUIREMENT: { mandatory: false },
            TERMS: { mandatory: false },
            PRIVACY: { mandatory: false },
        })

        provide('errors', errors)
        provide('formModel', formModel)

        const showClientFee = computed(() => store.state.config?.show_client_fee_euro_amount)

        const setImageOrientation = () => {
            const zlbImage = document.querySelector('.zlb-image') as HTMLImageElement
            if (zlbImage?.naturalWidth > zlbImage?.naturalHeight) {
                zlbImage.classList.add('landscape')
                zlbImage.classList.remove('portrait')
            } else if (zlbImage?.naturalWidth < zlbImage?.naturalHeight) {
                zlbImage.classList.add('portrait')
                zlbImage.classList.remove('landscape')
            }
        }

        const showBtnLabelName = computed(() => {
            return showMore.value
                ? t('registrations.block.show_less')
                : t('registrations.block.show_more')
        })

        /**
         * Zoom ZLB image on hover
         * call function from child wrapper to avoid glitch created by .image-wrapper border
         * @param event MouseEvent
         */
        const moveZoomImage = (event: MouseEvent) => {
            const imageWrapper = event.currentTarget as HTMLDivElement
            const image = imageWrapper.querySelector('.zlb-image') as HTMLImageElement
            const zoomImage = imageWrapper.querySelector('.zoom') as HTMLImageElement

            if (image && zoomImage) {
                // Calculate the position of the zoomed image
                zoomImage.style.left =
                    ((event.offsetX / imageWrapper.clientWidth) *
                        (image.clientWidth - zoomImage.clientWidth)) /
                        2 +
                    'px'

                zoomImage.style.top =
                    ((event.offsetY / imageWrapper.clientHeight) *
                        (image.clientHeight - zoomImage.clientHeight)) /
                        2 +
                    'px'
            }
        }

        const resubmit = () => {
            if (termsListRef.value) {
                termsListRef.value.validateForm()
            }
            // check if the form has errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            emit('resubmit-registration', {
                selectedYear: selectedYear.value,
                id: props.item?.id,
                formData: formModel.value,
            })
            cancelPopup()
        }

        const openModal = () => {
            isOpenModal.value = true
            selectedYear.value = null
        }

        // store selected formdata for resubmission
        const termsAndPrivacyClickHandler = (params: TermsAndConditionsDTO) => {
            const data = {
                resubmitData: props.item,
                tnc: params,
                selectedYear: selectedYear.value,
                pageNumber: props.pageNumber,
            }

            /* store user inputs to be able to restore when user refresh the page or when user comes back from privacy/terms links */
            store.commit('setResubmitPopupDetailsEUP', data)
        }

        const cancelPopup = () => {
            isOpenModal.value = false
            store.commit('setResubmitPopupDetailsEUP', null)
            localStorage.removeItem(variables.LOCAL_STORAGE_ITEMS.RESUBMIT_TNC)
            displayTnC.value = false
            selectedYear.value = null
            errors.value = {
                CURRENT_YEAR: { mandatory: false },
                NEXT_YEAR: { mandatory: false },
                ADDITIONAL_REQUIREMENT: { mandatory: false },
                TERMS: { mandatory: false },
                PRIVACY: { mandatory: false },
            }
            formModel.value = { ...initialValues }
        }

        onMounted(() => {
            // display popup and show the selection by default, if user has entered them before
            if (
                store.state.resubmitPopupDetailsEUP &&
                store.state.resubmitPopupDetailsEUP.resubmitData?.sequence === props.item?.sequence
            ) {
                openModal()
                formModel.value = store.state.resubmitPopupDetailsEUP.tnc
                selectedYear.value = store.state.resubmitPopupDetailsEUP.selectedYear
                if (selectedYear.value) {
                    displayTnC.value = true
                }
            }
        })

        return {
            setImageOrientation,
            showMore,
            showBtnLabelName,
            showClientFee,
            utils,
            isOpenModal,
            selectedYear,
            availableYears,
            moveZoomImage,
            resubmit,
            openModal,
            StatusTimelines,
            QAStatus,
            termsListRef,
            termsAndPrivacyClickHandler,
            displayTnC,
            cancelPopup,
            displayNoYearAvailableMessage:
                store.state.config.qa_create_choices?.no_allowed_registration_years_message,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.image-wrapper {
    position: relative;
    flex: 1;
    overflow: hidden;
    border: 2px solid #a7a7a7;
    border-radius: 6px;
    line-height: 0;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px #888;
    &:hover {
        .zlb-image {
            opacity: 0;
        }
        .zoom {
            opacity: 1;
        }
    }
    .zlb-image {
        object-fit: cover;
        max-width: 100%;
        image-orientation: from-image;

        &.portrait {
            max-width: 50%;
        }
    }
    .zoom {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 200%;
    }
}
.right-block,
.resubmit-btn {
    display: flex;
    justify-content: flex-end;
}
.p-card {
    border: 1px solid #0000002b;
}
.file-name {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
}
.greenText {
    color: $green;
}
.redText {
    color: $red;
}
.sub-text {
    color: $medium-grey;
}
.future-status-text {
    color: $light-grey;
}
.client-fee-breakdown {
    .label,
    .value {
        font-size: 12px !important;
        color: $medium-grey;
    }
}
</style>
